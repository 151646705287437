export const INIT_LIST = [
  // 注：经纬度x和y都是假数据
  {
    videoSrc: '',
    name: '闸航路路面球机1',
    hls: 'qP5GKuIrB1EJB3JSM8KQQU',
    x: '4385.441368963452',
    y: '-20638.786524233987'
  },
  {
    videoSrc: '',
    name: '闸航路路面球机2',
    hls: 'qP5GKuIrB1EJB3JSM8KR2J',
    x: '3914.3237224226',
    y: '-19053.3493558921'
  },
  {
    videoSrc: '',
    name: '鹰眼-召稼楼全景视频',
    hls: 'qP5GKuIrB1F349RP8GONS6',
    x: '7402.4589748742',
    y: '-17574.8421829216'
  },
  {
    videoSrc: '',
    name: '欣康苑垃圾房监控',
    hls: 'qP5GKuIrB1EJB3JSU6DRN8',
    x: '2837.9837149295',
    y: '-23308.0438409738'
  },
  {
    videoSrc: '',
    name: '瑞和城陆街区垃圾房监控',
    hls: 'qP5GKuIrB1EJB3JSU5F8SE',
    x: '7023.150903248191',
    y: '-18151.54105959082'
  },
  {
    videoSrc: '',
    name: '瑞和城陆街区临时垃圾点监控',
    hls: 'qP5GKuIrB1EJB3JSU5F8T3',
    x: '4222.176515735707',
    y: '-22486.081420045564'
  },
  {
    videoSrc: '',
    name: '中城新苑垃圾房监控',
    hls: 'qP5GKuIrB1EJB3JSU5F9DL',
    x: '2530.7436245961903',
    y: '-22620.46898420664'
  },
  {
    videoSrc: '',
    name: '新汇绿苑一村东区垃圾房',
    hls: 'qP5GKuIrB1EJB3JT8DIROV',
    x: '6185.226699370483',
    y: '-19267.41636532593'
  },
  {
    videoSrc: '',
    name: '新汇绿苑一村西区垃圾房',
    hls: 'qP5GKuIrB1EJB3JT8CKB0N',
    x: '6168.719992371287',
    y: '-20758.477990850202'
  },
  {
    videoSrc: '',
    name: '革新村徐家宅垃圾厢房',
    hls: 'qP5GKuIrB1EJB3JSMEC6JM',
    x: '6494.74012802376',
    y: '-18365.16165870723'
  },
  {
    videoSrc: '',
    name: '瑞和城叁街区43弄垃圾房监控',
    hls: 'qP5GKuIrB1EJB3JSU6DQS4',
    x: '4747.1236506591',
    y: '-23548.7561241937'
  },
  {
    videoSrc: '',
    name: '瑞和雅苑260弄垃圾房监控',
    hls: 'qP5GKuIrB1EJB3JSU7CCJL',
    x: '6544.7137251856',
    y: '-18444.4707426451'
  }
]

export const SEL_ICON_LIST = [
  {
    limit: 1,
    src: require('@/assets/image/video-limit1.png'),
    srcActive: require('@/assets/image/video-limit1-active.png')
  },
  {
    limit: 4,
    src: require('@/assets/image/video-limit4.png'),
    srcActive: require('@/assets/image/video-limit4-active.png')
  },
  {
    limit: 6,
    src: require('@/assets/image/video-limit6.png'),
    srcActive: require('@/assets/image/video-limit6-active.png')
  },
  {
    limit: 8,
    src: require('@/assets/image/video-limit8.png'),
    srcActive: require('@/assets/image/video-limit8-active.png')
  },
  {
    limit: 9,
    src: require('@/assets/image/video-limit9.png'),
    srcActive: require('@/assets/image/video-limit9-active.png')
  }
]

export function getGrid(pageLimit, screenStatus) {
  let str = ''
  switch (pageLimit) {
    case 1:
      str =
        'grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, minmax(100px, 1fr));'
      break
    case 4:
      str =
        'grid-template-columns: repeat(2, 1fr);grid-template-rows: repeat(2, minmax(100px, 1fr));'
      break
    case 6:
      str =
        'grid-template-columns: repeat(3, 1fr);grid-template-rows: repeat(2, minmax(100px, 1fr));'
      break
    case 8:
      if (screenStatus) {
        str =
          'grid-template-columns: repeat(4, 1fr);grid-template-rows: repeat(2, minmax(100px, 1fr));'
      } else {
        str =
          'grid-template-columns: repeat(2, 1fr);grid-template-rows: repeat(4, minmax(100px, 1fr));'
      }
      break
    case 9:
      str =
        'grid-template-columns: repeat(3, 1fr);grid-template-rows: repeat(3, minmax(100px, 1fr));'
      break
  }
  return str
}
