import { getVideoHlsApi, videoTransformApi, videoPresetApi } from '@/api/video'
import { Message } from 'element-ui'

// 预置点位接口-4合1
async function presetHandleFunc(type, params = undefined, hls, showMessage = false, cb) {
  let data = {
    transform_hls: hls,
    type
  }
  if (params) {
    data = {
      ...data,
      ...params
    }
  }
  try {
    let res = await videoPresetApi(data)
    if (res.result == 0) {
      cb && cb(res.data)
    } else {
      if (showMessage) {
        Message({
          message: res.msg || '接口异常，请稍后再试',
          type: 'warning'
        })
      }
    }
  } catch (error) {
    if (showMessage) {
      Message({
        message: '接口异常，请稍后再试',
        type: 'warning'
      })
    }
  }
}

const state = {
  pointList: [] //预留点位列表
}

const mutations = {
  updatePointList(state, arr) {
    state.pointList = arr
  }
}

const actions = {
  // 获取单个视频流
  async getVideoHlsAction({ commit }, { hls, cb }) {
    try {
      let res = await getVideoHlsApi({ transform_hls: hls, scheme: 'FLV_HTTP' })
      if (res.result == 0) {
        cb(res.data || '')
      } else {
        cb && cb('')
      }
    } catch (error) {
      console.log(error)
      cb && cb('')
    }
  },
  // 获取多个视频流
  async getVideoHlsArrAction({ commit }, { hlsArr, cb }) {
    let arr = [...hlsArr]
    try {
      for (let i = 0; i < hlsArr.length; i++) {
        let res = await getVideoHlsApi({ transform_hls: hlsArr[i].hls, scheme: 'FLV_HTTP' })
        arr[i].videoSrc = res.data || ''
      }
      cb && cb(arr)
    } catch (error) {
      console.log(error)
      cb && cb(arr)
    }
  },
  // 获取预设点位列表
  async getPresetListAction({ commit }, { hls }) {
    presetHandleFunc('get', undefined, hls, false, (res) => {
      commit('updatePointList', res || [])
    })
  },
  // 删除预设点位  params={index:}
  async delPresetAction({ dispatch }, { params, hls, cb }) {
    presetHandleFunc('delete', params, hls, true, (res) => {
      Message({
        message: '删除预置点位成功',
        type: 'success'
      })
      dispatch('getPresetListAction', { hls }) // 删除后刷新预设点位列表
    })
  },
  // 更新预设点位  params={index:,name:}
  async updatePresetAction({ dispatch }, { params, hls, cb }) {
    presetHandleFunc('put', params, hls, true, (res) => {
      Message({
        message: '更新预置点位成功',
        type: 'success'
      })
      dispatch('getPresetListAction', { hls }) // 更新后刷新预设点位列表
    })
  },
  // 跳转指定预设点位  params={index:}
  async gotoPresetAction({ commit }, { params, hls, cb }) {
    presetHandleFunc('goto', params, hls, true, (res) => {
      Message({
        message: '跳转预置点位成功',
        type: 'success'
      })
      cb && cb()
    })
  },
  // 云台转动/放大缩小
  async videoTransformAction({ commit }, { params, hls, successCb, failCb }) {
    try {
      let res = await videoTransformApi({ transform_hls: hls, speed: 3, ...params })
      if (res.result == 0) {
        successCb && successCb()
      } else {
        failCb && failCb()
        Message({
          message: res.msg || '接口异常，请稍后再试',
          type: 'error'
        })
      }
    } catch (error) {
      failCb && failCb()
      Message({
        message: '接口异常，请稍后再试',
        type: 'error'
      })
    }
  }
}

const getters = {
  pointList(state) {
    return state.pointList
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
