/*
 * @Descripttion:
 * @version: 1.1
 * @Author: @qing
 * @Date: 2023-02-20 10:50:48
 * @LastEditors: @qing
 * @LastEditTime: 2023-02-20 11:14:37
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import dialogAction from '@/store/modules/dialogAction'
import mapAction from '@/store/modules/mapAction'
import themeUI from './modules/themeUI'
import user from './modules/user'
import video from './modules/video'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checkVisiable: false,
    checkedCount: 0,
    currentCommCode: 'T0001'
  },
  getters,
  mutations: {
    updateCount(state) {
      state.checkedCount++
    },
    changeCheckVis(state, bool) {
      state.checkVisiable = bool
    },
    updateCommName(state, name) {
      state.currentCommCode = name
    }
  },
  actions: {},
  modules: {
    dialogAction,
    mapAction,
    themeUI,
    user,
    video
  }
})
