import { apartmentList } from '@/api/user'

const state = {
  apartList: [],
  apartListAll: []
}

const mutations = {
  updateApartment(state, arr) {
    state.apartList = arr
  },
  updateApartmentAll(state, arr) {
    state.apartListAll = arr
  }
}

const actions = {
  async getApartmentList({ commit }) {
    try {
      // const [res, { data: res1 }, { data: res2 }, { data: res3 }] = await Promise.all([
      //   apartmentList(),
      //   apartmentList({ deep: 1, parentCode: 1000 }),
      //   apartmentList({ deep: 1, parentCode: 2000 }),
      //   apartmentList({ deep: 1, parentCode: 3000 })
      // ])
      const res = {
        result: 0,
        msg: 'success',
        data: [
          {
            id: 1,
            deptCode: 1000,
            parentCode: null,
            deptName: '村委',
            deptType: null,
            telephone: null,
            description: null,
            createdAt: '2022-07-06 14:29:00',
            updatedAt: '2022-07-06 14:29:00',
            deletedAt: null
          },
          {
            id: 2,
            deptCode: 2000,
            parentCode: null,
            deptName: '居委',
            deptType: null,
            telephone: null,
            description: null,
            createdAt: '2022-07-06 14:29:00',
            updatedAt: '2022-07-06 14:29:00',
            deletedAt: null
          },
          {
            id: 3,
            deptCode: 3000,
            parentCode: null,
            deptName: '部门',
            deptType: null,
            telephone: null,
            description: null,
            createdAt: '2022-07-06 14:29:00',
            updatedAt: '2022-07-06 14:29:00',
            deletedAt: null
          }
        ]
      }
      const res1 = [
        {
          id: 4,
          deptCode: 1001,
          parentCode: 1000,
          deptName: '东风村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:00',
          updatedAt: '2022-07-06 14:29:00',
          deletedAt: null
        },
        {
          id: 5,
          deptCode: 1002,
          parentCode: 1000,
          deptName: '立民村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:00',
          updatedAt: '2022-07-06 14:29:00',
          deletedAt: null
        },
        {
          id: 6,
          deptCode: 1003,
          parentCode: 1000,
          deptName: '苏民村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:00',
          updatedAt: '2022-07-06 14:29:00',
          deletedAt: null
        },
        {
          id: 7,
          deptCode: 1004,
          parentCode: 1000,
          deptName: '勤劳村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:00',
          updatedAt: '2022-07-06 14:29:00',
          deletedAt: null
        },
        {
          id: 8,
          deptCode: 1005,
          parentCode: 1000,
          deptName: '建中村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:00',
          updatedAt: '2022-07-06 14:29:00',
          deletedAt: null
        },
        {
          id: 9,
          deptCode: 1006,
          parentCode: 1000,
          deptName: '知新村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:00',
          updatedAt: '2022-07-06 14:29:00',
          deletedAt: null
        },
        {
          id: 10,
          deptCode: 1007,
          parentCode: 1000,
          deptName: '友建村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 11,
          deptCode: 1008,
          parentCode: 1000,
          deptName: '为民村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 12,
          deptCode: 1009,
          parentCode: 1000,
          deptName: '徐凌村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 13,
          deptCode: 1010,
          parentCode: 1000,
          deptName: '李巷村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 14,
          deptCode: 1011,
          parentCode: 1000,
          deptName: '恒星村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 15,
          deptCode: 1012,
          parentCode: 1000,
          deptName: '叶凌村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 16,
          deptCode: 1013,
          parentCode: 1000,
          deptName: '联星村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 17,
          deptCode: 1014,
          parentCode: 1000,
          deptName: '镇北村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 18,
          deptCode: 1015,
          parentCode: 1000,
          deptName: '联胜村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 19,
          deptCode: 1016,
          parentCode: 1000,
          deptName: '万里村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 20,
          deptCode: 1017,
          parentCode: 1000,
          deptName: '革新村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 21,
          deptCode: 1018,
          parentCode: 1000,
          deptName: '胜利村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 22,
          deptCode: 1019,
          parentCode: 1000,
          deptName: '联合村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 23,
          deptCode: 1020,
          parentCode: 1000,
          deptName: '建新村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 24,
          deptCode: 1021,
          parentCode: 1000,
          deptName: '跃进村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 25,
          deptCode: 1022,
          parentCode: 1000,
          deptName: '联民村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 26,
          deptCode: 1023,
          parentCode: 1000,
          deptName: '杜行村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 27,
          deptCode: 1024,
          parentCode: 1000,
          deptName: '张行村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 28,
          deptCode: 1025,
          parentCode: 1000,
          deptName: '康桥村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 29,
          deptCode: 1026,
          parentCode: 1000,
          deptName: '群益村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 30,
          deptCode: 1027,
          parentCode: 1000,
          deptName: '建岗村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 31,
          deptCode: 1028,
          parentCode: 1000,
          deptName: '建东村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 32,
          deptCode: 1029,
          parentCode: 1000,
          deptName: '亭子村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 33,
          deptCode: 1030,
          parentCode: 1000,
          deptName: '永新村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 34,
          deptCode: 1031,
          parentCode: 1000,
          deptName: '永丰村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 35,
          deptCode: 1032,
          parentCode: 1000,
          deptName: '汇西村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 36,
          deptCode: 1033,
          parentCode: 1000,
          deptName: '永建村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 37,
          deptCode: 1034,
          parentCode: 1000,
          deptName: '汇中村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 38,
          deptCode: 1035,
          parentCode: 1000,
          deptName: '汇南村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 39,
          deptCode: 1036,
          parentCode: 1000,
          deptName: '汇红村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 40,
          deptCode: 1037,
          parentCode: 1000,
          deptName: '正义村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 41,
          deptCode: 1038,
          parentCode: 1000,
          deptName: '光继村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 42,
          deptCode: 1039,
          parentCode: 1000,
          deptName: '北徐村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 43,
          deptCode: 1040,
          parentCode: 1000,
          deptName: '汇北村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 44,
          deptCode: 1041,
          parentCode: 1000,
          deptName: '先进村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 45,
          deptCode: 1042,
          parentCode: 1000,
          deptName: '新风村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 46,
          deptCode: 1043,
          parentCode: 1000,
          deptName: '汇东村',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        }
      ]
      const res2 = [
        {
          id: 47,
          deptCode: 2001,
          parentCode: 2000,
          deptName: '杜行居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 48,
          deptCode: 2002,
          parentCode: 2000,
          deptName: '鲁汇居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 49,
          deptCode: 2003,
          parentCode: 2000,
          deptName: '闵浦一居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 50,
          deptCode: 2004,
          parentCode: 2000,
          deptName: '闵浦二居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 51,
          deptCode: 2005,
          parentCode: 2000,
          deptName: '闵浦三居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 52,
          deptCode: 2006,
          parentCode: 2000,
          deptName: '浦航二居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 53,
          deptCode: 2007,
          parentCode: 2000,
          deptName: '浦航三居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 54,
          deptCode: 2008,
          parentCode: 2000,
          deptName: '浦航四居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 55,
          deptCode: 2009,
          parentCode: 2000,
          deptName: '浦航五居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 56,
          deptCode: 2010,
          parentCode: 2000,
          deptName: '浦航六居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 57,
          deptCode: 2011,
          parentCode: 2000,
          deptName: '浦航七居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 58,
          deptCode: 2012,
          parentCode: 2000,
          deptName: '浦航八居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 59,
          deptCode: 2013,
          parentCode: 2000,
          deptName: '宝邸一居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:01',
          updatedAt: '2022-07-06 14:29:01',
          deletedAt: null
        },
        {
          id: 60,
          deptCode: 2014,
          parentCode: 2000,
          deptName: '宝邸二居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 61,
          deptCode: 2015,
          parentCode: 2000,
          deptName: '新汇居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 62,
          deptCode: 2016,
          parentCode: 2000,
          deptName: '浦润苑居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 63,
          deptCode: 2017,
          parentCode: 2000,
          deptName: '中虹浦江苑居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 64,
          deptCode: 2018,
          parentCode: 2000,
          deptName: '欣佳宝邸居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 65,
          deptCode: 2019,
          parentCode: 2000,
          deptName: '智汇园居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 66,
          deptCode: 2020,
          parentCode: 2000,
          deptName: '瑞和雅苑一居委（筹）',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 67,
          deptCode: 2021,
          parentCode: 2000,
          deptName: '浦江馨都居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 68,
          deptCode: 2022,
          parentCode: 2000,
          deptName: '聚缘居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 69,
          deptCode: 2023,
          parentCode: 2000,
          deptName: '红梅苑居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 70,
          deptCode: 2024,
          parentCode: 2000,
          deptName: '永康城一居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 71,
          deptCode: 2025,
          parentCode: 2000,
          deptName: '永康城二居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 72,
          deptCode: 2026,
          parentCode: 2000,
          deptName: '永康城三居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 73,
          deptCode: 2027,
          parentCode: 2000,
          deptName: '永康城四居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 74,
          deptCode: 2028,
          parentCode: 2000,
          deptName: '永康城五居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 75,
          deptCode: 2029,
          parentCode: 2000,
          deptName: '永康城六居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 76,
          deptCode: 2030,
          parentCode: 2000,
          deptName: '永康城七居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 77,
          deptCode: 2031,
          parentCode: 2000,
          deptName: '永康城八居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 78,
          deptCode: 2032,
          parentCode: 2000,
          deptName: '永康城九居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 79,
          deptCode: 2033,
          parentCode: 2000,
          deptName: '汇秀景苑居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 80,
          deptCode: 2034,
          parentCode: 2000,
          deptName: '瑞和华苑居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 81,
          deptCode: 2035,
          parentCode: 2000,
          deptName: '瑞和城一居委（筹）',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 82,
          deptCode: 2036,
          parentCode: 2000,
          deptName: '瑞和城二居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 83,
          deptCode: 2037,
          parentCode: 2000,
          deptName: '瑞和城三居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 84,
          deptCode: 2038,
          parentCode: 2000,
          deptName: '瑞和城四居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 85,
          deptCode: 2039,
          parentCode: 2000,
          deptName: '瑞和城五居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 86,
          deptCode: 2040,
          parentCode: 2000,
          deptName: '瑞和城六居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 87,
          deptCode: 2041,
          parentCode: 2000,
          deptName: '瑞和雅苑二居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 88,
          deptCode: 2042,
          parentCode: 2000,
          deptName: '馨和雅苑居委',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        }
      ]
      const res3 = [
        {
          id: 89,
          deptCode: 3001,
          parentCode: 3000,
          deptName: '党政办',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 90,
          deptCode: 3002,
          parentCode: 3000,
          deptName: '党群办',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 91,
          deptCode: 3003,
          parentCode: 3000,
          deptName: '人武部',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 92,
          deptCode: 3004,
          parentCode: 3000,
          deptName: '水务站（环保办）',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 93,
          deptCode: 3005,
          parentCode: 3000,
          deptName: '规建办',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 94,
          deptCode: 3006,
          parentCode: 3000,
          deptName: '平安办',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 95,
          deptCode: 3007,
          parentCode: 3000,
          deptName: '安监所',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 96,
          deptCode: 3008,
          parentCode: 3000,
          deptName: '机管所',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 97,
          deptCode: 3009,
          parentCode: 3000,
          deptName: '城运中心',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 98,
          deptCode: 3010,
          parentCode: 3000,
          deptName: '城建中心',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 99,
          deptCode: 3011,
          parentCode: 3000,
          deptName: '综合行政执法中队',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 100,
          deptCode: 3012,
          parentCode: 3000,
          deptName: '农业服务中心',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 101,
          deptCode: 3013,
          parentCode: 3000,
          deptName: '文体中心',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 102,
          deptCode: 3014,
          parentCode: 3000,
          deptName: '社区中心',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 103,
          deptCode: 3015,
          parentCode: 3000,
          deptName: '社事办',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 104,
          deptCode: 3016,
          parentCode: 3000,
          deptName: '防控办',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 105,
          deptCode: 3017,
          parentCode: 3000,
          deptName: '社区卫生中心',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 106,
          deptCode: 3018,
          parentCode: 3000,
          deptName: '房管所',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 107,
          deptCode: 3019,
          parentCode: 3000,
          deptName: '重大办',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 108,
          deptCode: 3020,
          parentCode: 3000,
          deptName: '绿化办',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 109,
          deptCode: 3021,
          parentCode: 3000,
          deptName: '企业服务中心',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 110,
          deptCode: 3022,
          parentCode: 3000,
          deptName: '招商公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 111,
          deptCode: 3023,
          parentCode: 3000,
          deptName: '经济总公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 112,
          deptCode: 3024,
          parentCode: 3000,
          deptName: '古镇公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 113,
          deptCode: 3025,
          parentCode: 3000,
          deptName: '江月公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 114,
          deptCode: 3026,
          parentCode: 3000,
          deptName: '浦投公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 115,
          deptCode: 3027,
          parentCode: 3000,
          deptName: '房产公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 116,
          deptCode: 3028,
          parentCode: 3000,
          deptName: '都市公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 117,
          deptCode: 3029,
          parentCode: 3000,
          deptName: '市政公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 118,
          deptCode: 3030,
          parentCode: 3000,
          deptName: '物业公司',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 119,
          deptCode: 3031,
          parentCode: 3000,
          deptName: '杜行派出所',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 120,
          deptCode: 3032,
          parentCode: 3000,
          deptName: '鲁汇派出所',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        },
        {
          id: 121,
          deptCode: 3033,
          parentCode: 3000,
          deptName: '漕河泾开发区/临港',
          deptType: null,
          telephone: null,
          description: null,
          createdAt: '2022-07-06 14:29:02',
          updatedAt: '2022-07-06 14:29:02',
          deletedAt: null
        }
      ]
      if (res.result === 0) {
        commit('updateApartment', res.data)
      }
      commit('updateApartmentAll', [res1, res2, res3])
    } catch (e) {
      console.error(e)
    }
  }
}

const getters = {
  apartListAllArr(state) {
    let arr = state.apartListAll
    return [...arr[0], ...arr[1], ...arr[2]]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
