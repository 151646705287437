import request from '@/utils/request'

// 获取视频流
export function getVideoHlsApi(data = {}) {
  return request({
    url: 'https://api.saas.famesmart.com/alarm-create' + '/transformHls',
    method: 'get',
    params: data
  })
}

// 鹰眼操作接口-转动/缩放
export function videoTransformApi(data = {}) {
  return request({
    url: 'https://api.saas.famesmart.com/alarm-create' + '/headRotation',
    method: 'get',
    params: data
  })
}

// 预设点位操作接口-获取预设列表/更新预设点/删除预设点/跳转指定预设点
export function videoPresetApi(data = {}) {
  return request({
    url: 'https://api.saas.famesmart.com/alarm-create' + '/preset',
    method: 'get',
    params: data
  })
}

export function getTelHLS(data) {
  return request({
    url: 'http://32.1.101.100:88/get_tel_hls',
    method: 'GET',
    params: data
  })
}
