import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/CommonLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/login/loginIndex'),
    hidden: true
  },
  {
    path: '/center',
    redirect: '/sign',
    component: Layout,
    children: [
      {
        path: '/block',
        name: 'BlockView',
        components: {
          left: () => import('@/views/Block/BlockViewLeft.vue'),
          right: () => import('@/views/Block/BlockViewRight.vue')
        }
      },
      {
        path: '/monitor',
        name: 'MonitorView',
        components: {
          left: () => import('@/views/Monitor/MonitorViewLeft.vue'),
          right: () => import('@/views/Monitor/MonitorViewRight.vue')
        }
      },
      {
        path: '/flood',
        name: 'FloodView',
        components: {
          left: () => import('@/views/Flood/FloodViewLeft.vue'),
          right: () => import('@/views/Flood/FloodViewRight.vue')
        }
      },
      {
        path: '/fire',
        name: 'FireView',
        components: {
          left: () => import('@/views/Fire/FireViewLeft.vue'),
          right: () => import('@/views/Fire/FireViewRight.vue')
        }
      },
      {
        path: '/sign',
        name: 'SignView',
        components: {
          left: () => import('@/views/SignData/SignViewLeft.vue'),
          right: () => import('@/views/SignData/SignViewRight.vue')
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
