const state = {
  mapPointData: '{}',
  mapFilterData: '',
  mapFilterVideoData: '[]',
  mapVideoListForEqu: '{}'
}
const mutations = {
  MAP_POINT_DATA: (state, mapPointData) => {
    state.mapPointData = mapPointData
  },
  MAP_FILTER_DATA: (state, mapFilterData) => {
    state.mapFilterData = mapFilterData
  },
  MAP_FILTER_VIDEO_DATA: (state, mapFilterVideoData) => {
    state.mapFilterVideoData = mapFilterVideoData
  },
  MAP_VIDEO_LIST_FOR_EQU: (state, mapVideoListForEqu) => {
    state.mapVideoListForEqu = mapVideoListForEqu
  }
}
const actions = {
  mapPointData(context, mapPointData) {
    context.commit('MAP_POINT_DATA', mapPointData)
  },
  mapFilterData(context, mapFilterData) {
    context.commit('MAP_FILTER_DATA', mapFilterData)
  },
  mapFilterVideoData(context, mapFilterVideoData) {
    context.commit('MAP_FILTER_VIDEO_DATA', mapFilterVideoData)
  },
  mapVideoListForEqu(context, mapVideoListForEqu) {
    context.commit('MAP_VIDEO_LIST_FOR_EQU', mapVideoListForEqu)
  }
}
export default { namespaced: true, state, mutations, actions }
