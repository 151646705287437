export const FLOOD_POWER_DATA_NEW = [
    {
        name: '上海闵行市政建设有限公司 金国祥 18017121717',
        value: 14,
        children: null,
        contact: '金国祥',
        depatname: '总经理',
        telephone: '18017121717',
        num: 25,
        area: '',
        x: '4876.11839657218',
        y: '-13368.411862414192',
        top: '12%',
        left: '20%',
        color: 'rgba(227,75,96,0.5)',
        textColor: 'rgb(227,75,96)',
        rings: [
            [4942.316993714594, -14663.17429357598],
            [5974.298546874954, -14143.57628780048],
            [6662.965961568285, -15594.416856240043],
            [8748.072808650804, -14890.793982333902],
            [8620.639419344678, -14684.517503368124],
            [8791.613566437654, -14613.370361028366],
            [8501.187050643359, -14157.81816336784],
            [8593.929652973022, -14086.605226680014],
            [8217.704559242367, -13680.521527906505],
            [8516.96986228696, -13616.376601395334],
            [8055.232166802947, -13145.75989377166],
            [7406.594907866711, -13316.938174402836],
            [7534.330168306297, -13559.352830761447],
            [7199.36498811659, -13701.901566641567],
            [6822.867926099845, -13038.747378327767],
            [6766.018634989316, -12924.578566785378],
            [6352.535289191214, -13124.358996495392],
            [6053.680058485333, -12589.07551721679],
            [4165.294364828956, -13095.823217890937],
            [3793.868919070361, -12360.53500343215],
            [3067.447254501884, -12696.823525371097],
            [3167.10496095346, -13067.431717368794],
            [2903.668179406023, -13095.930064582242],
            [2618.609427783515, -12297.437490605107],
            [2055.881176081144, -12454.372498572437],
            [1805.9284676253249, -11862.07360223036],
            [1292.9403167539829, -11990.576184889333],
            [759.9086074568527, -12739.598303834668],
            [1024.3495611289636, -13274.012465045726],
            [1323.07258134036, -13095.930064582242],
            [1587.6434164670288, -14014.238603049429],
            [2142.4174349606137, -13850.617543710188],
            [2242.1942291909454, -14099.587459548206],
            [2875.2799997559473, -13928.876956991375],
            [3328.369112557139, -15420.11879788767]
        ]
    },
    {
        name: '上海达富环境科技有限公司 张黎明 13916278960',
        value: 14,
        children: null,
        contact: '张黎明',
        depatname: '总经理',
        telephone: '13916278960',
        num: 11,
        area: '南至大治河，东至浦星公路',
        x: '7699.246559336838',
        y: '-17408.872640620128',
        top: '62%',
        left: '260px',
        color: 'rgba(183,117,163,0.5)',
        textColor: 'rgb(183,65,235)',
        rings: [
            [9048.090794834981, -15158.275673255412],
            [9203.197376392418, -15572.672197966322],
            [7368.735629420427, -16252.312687502868],
            [7796.792281366527, -16931.21433466142],
            [8615.95173240227, -16580.150481383214],
            [9286.09076893688, -16744.004848867593],
            [9425.785301427153, -16985.80652634444],
            [9964.087424429672, -16829.81616617721],
            [10041.556326955442, -16985.80652634444],
            [8660.489142663782, -17695.070750617982],
            [9759.019642850735, -20692.605263016143],
            [7074.821647802917, -21520.844633317472],
            [7297.3005525020035, -17619.766591163276],
            [6684.590988719585, -15727.143896505746],
        ]
    },
    {
        name: '上海浦岗建设工程有限公司 孙亮 18117421097',
        value: 14,
        children: null,
        contact: '孙亮',
        depatname: '总经理',
        telephone: '18117421097',
        num: 23,
        area: '',
        x: '6605.650482560495',
        y: '-25016.407328964608',
        top: '76%',
        left: '50px',
        color: 'rgba(153,208,181,0.5)',
        textColor: 'rgb(70,212,110)',
        rings: [
            [1737.2563449986328, -24197.63626447489],
            [2320.8469151556064, -26231.241597975244],
            [4365.643941599665, -25220.99154455942],
            [4655.915890707004, -26135.072906436188],
            [7364.33977403626, -27144.365852810308],
            [7737.307201204811, -27168.383756585514],
            [7869.828681165371, -26615.82043511022],
            [9555.333940248545, -26074.962601461546],
            [8571.639858601084, -21060.620839729705],
            [7280.20775560439, -21519.74395250465],
            [7629.697939713445, -23751.83641015627],
            [1795.4791404272876, -23511.81275866589]
        ]
    },
    {
        name: '上海聚投实业有限公司 张庆国 13482824228',
        value: 14,
        children: null,
        contact: '张庆国',
        depatname: '总经理',
        telephone: '13482824228',
        num: 25,
        area: '',
        x: '4620.607710582693',
        y: '-16572.33427891772',
        top: '12%',
        left: '20%',
        color: 'rgba(76,79,212,0.5)',
        textColor: 'rgb(227,75,96)',
        rings: [
            [6001.576496856001, -14207.590851572582],
            [6678.232253396767, -15740.833921766407],
            [7166.166548761319, -17260.507343036315],
            [7095.9928202121055, -18448.268458992465],
            [3904.3917683038, -19358.66214122538],
            [3328.369112557139, -15420.11879788767]
        ]
    },
    {
        name: '上海丰达市政管道有限公司 周征 15021247420',
        value: 14,
        children: null,
        contact: '周征',
        depatname: '总经理',
        telephone: '15021247420',
        num: 25,
        area: '',
        x: '4558.878041857059',
        y: '-19978.168474106693',
        top: '12%',
        left: '20%',
        color: 'rgba(107,118,156,0.5)',
        textColor: 'rgb(227,75,96)',
        rings: [
            [7162.005193740757, -19811.041697178705],
            [2330.112528842511, -21475.801760910334],
            [2388.437009612418, -20687.75780095811],
            [2303.360327710176, -19724.03341168392],
            [7090.082784157463, -18341.061309676646]
        ]
    },
    {
        name: '上海霖炬建设工程有限公司 陆纪华 13564720392',
        value: 14,
        children: null,
        contact: '陆纪华',
        depatname: '总经理',
        telephone: '13564720392',
        num: 7,
        area: '',
        x: '4830.205011383967',
        y: '-21269.736569050547',
        top: '45%',
        left: '16%',
        color: 'rgba(71,136,149,0.5)',
        textColor: 'rgb(221,236,89)',
        rings: [
            [2511.670906189607, -22113.15854977024],
            [5689.079603941582, -21656.769997739437],
            [7259.186431876828, -21571.643391644644],
            [7074.801876450131, -20029.60253905812],
            [3986.4539123109826, -20990.9226948121],
            [2330.112528842511, -21475.801760910334]
        ]
    },
    {
        name: '上海成铭建筑工程有限公司 高海军 13764529583',
        value: 14,
        children: null,
        contact: '高海军',
        depatname: '总经理',
        telephone: '13764529583',
        num: 9,
        area: '',
        x: '4670.3275979620585',
        y: '-22738.66055294456',
        top: '28%',
        left: '210px',
        color: 'rgba(100,100,120,0.5)',
        textColor: 'rgb(76,79,212)',
        rings: [
            [7260.514429822912, -21570.053202519513],
            [7644.023112079918, -23727.853722112],
            [2544.809069883763, -23519.362762621622],
            [2456.217221981274, -22135.365734326602]
        ]
    }
]