<template>
  <div class="layout">
    <iframe v-show="xzappShow" id="xzapp" :src="iframeSrc" frameborder="0" />
    <div v-show="xzappShow" class="close-iframe cursor" @click="xzappShow = !xzappShow">
      返回主页面
    </div>
    <!-- 背景动效 -->
    <div class="background-effect">
      <div class="flashing-line line1"></div>
    </div>
    <div class="layout-header">
      <div class="header-side left">
        <!-- 一键切换主题 -->
        <div class="select-box">
          <!-- <el-select v-model="themeValue" placeholder="请选择" size="mini" @change="changeTheme()">
            <el-option
              v-for="item in options"
              :key="item.theme"
              :label="item.btnText"
              :value="item.theme"
            >
            </el-option>
          </el-select> -->
        </div>
        <!-- 天气
          <span class="date-s mr-50">多云</span><span>空气质量 优</span
        ><span class="time-s ml-50">PM2.5 28</span> -->
      </div>
      <div class="title-wrap full-bg">
        <h2 class="title">浦江镇一网统管驾驶舱</h2>
      </div>
      <!-- 时间 -->
      <div class="header-side right">
        <!-- <span class="date-s mr-50">{{ time.date }}</span
        ><span>{{ time.week }}</span
        ><span class="time-s ml-50">{{ time.time }}</span> -->
      </div>
    </div>
    <div class="slider left" :class="{ slideIn: slideIn }">
      <router-view name="left" />
    </div>
    <div class="slider right" :class="{ slideIn: slideIn }">
      <router-view name="right" />
    </div>
    <ScopeMaps />
    <div class="layout-bottom full-bg">
      <div class="layout-bottom__wrap">
        <div
          v-for="(item, index) in PATH"
          :key="`lybtn${item.class}`"
          class="btn-item full-bg"
          :class="{
            active: bottomIndex === index,
            border: index !== bottomIndex - 1 && index < PATH.length - 1
          }"
          @click="changePage(index)"
        >
          <svg-icon :icon-class="bottomIndex === index ? item.classActive : item.class" />
          <label>{{ item.label }}</label>
          <div class="flag-area" v-if="bottomIndex == 1 && index == 1">
            <div
              v-for="(ite, idx) in ['防汛防台', '城市安全']"
              :key="`flag${ite}`"
              class="flag"
              :class="{ activeFlag: flagIndex === idx + 1 }"
              @click.stop="routerEmergency(idx + 1)"
            >
              {{ ite }}
            </div>
          </div>
          <div class="flag-area three" v-if="bottomIndex == 4 && index == 4">
            <div
              v-for="(ite, idx) in ['浦江人大', '十个一件事', '浦江环卫']"
              :key="`flag${ite}`"
              class="flag"
              :class="{ activeFlag: flagIndex === idx + 3 }"
              @click.stop="routerEmergency(idx + 3)"
            >
              {{ ite }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Jieqi from '@/utils/jieqi'
import ScopeMaps from '@/components/Map/ScopeMaps'
const PATH = [
  {
    label: '浦江体征',
    class: 'tizheng1',
    classActive: 'tizheng2',
    name: 'SignView'
  },
  {
    label: '应急管理',
    class: 'xiaofang1',
    classActive: 'xiaofang2',
    name: 'CommunityView'
  },
  {
    label: '智慧街面',
    class: 'community1',
    classActive: 'community2',
    name: 'BlockView'
  },
  {
    label: '视频调度',
    class: 'video1',
    classActive: 'video2',
    name: 'MonitorView'
  },
  {
    label: '专题建设',
    class: 'renda1',
    classActive: 'renda2',
    name: 'CampusView'
  }
]
const PATH_INDEX = [
  {
    name: 'SignView',
    bottomIndex: 0,
    flagIndex: ''
  },
  {
    name: 'FloodView',
    bottomIndex: 1,
    flagIndex: 1
  },
  {
    name: 'FireView',
    bottomIndex: 1,
    flagIndex: 2
  },
  {
    name: 'BlockView',
    bottomIndex: 2,
    flagIndex: ''
  },
  {
    name: 'MonitorView',
    bottomIndex: 3,
    flagIndex: ''
  }
]
export default {
  components: {
    ScopeMaps
  },
  data() {
    return {
      iframeSrc: '',
      commCode: this.$store.state.currentCommCode,
      slideIn: false,
      PATH,
      time: {
        date: '',
        time: '00:00:00',
        week: '',
        calen: '',
        jieqi: ''
      },
      timer: null,
      xzappShow: false,
      themeValue: 'orange',
      options: [
        {
          btnText: '党政红',
          theme: 'orange'
        },
        {
          btnText: '党政蓝',
          theme: 'blue'
        }
      ],
      bottomIndex: 0,
      flagIndex: ''
    }
  },
  created() {
    let item = PATH_INDEX.find((item) => item.name === this.$route.name)
    this.bottomIndex = item.bottomIndex
    this.flagIndex = item.flagIndex
    let theme = localStorage.getItem('themeMYT') || 'orange'
    document.documentElement.setAttribute('theme', theme)
    this.$store.dispatch('themeUI/CHANGE_THEME_ACTION', theme)
  },
  mounted() {
    setTimeout(() => {
      this.slideIn = true
    }, 250)
    this.timer = setInterval(() => {
      this.time = Jieqi.showTime()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    changeTheme() {
      document.documentElement.setAttribute('theme', this.themeValue)
      localStorage.setItem('themeMYT', this.themeValue)
      this.$store.dispatch('themeUI/CHANGE_THEME_ACTION', this.themeValue)
    },
    changePage(i) {
      let name = PATH[i].name
      this.bottomIndex = i
      this.flagIndex = ''
      if (name === 'CampusView') {
        return
      }
      if (name === 'CommunityView') {
        return
      }
      this.slideIn = false
      setTimeout(() => {
        this.$router.push({ name })
        this.slideIn = true
      }, 500)
    },
    updateComm(name) {
      this.$store.commit('updateCommName', name)
    },
    routerEmergency(flag) {
      this.flagIndex = flag
      if (flag == 3) {
        window.open('http://renda-screen.saas.famesmart.com/Home', '_blank')
        return
      } else if (flag == 4) {
        window.open('http://pujiang-board.saas.famesmart.com/areaOne', '_blank')
        return
      } else if (flag == 5) {
        return
      }
      let name = ''
      if (flag == 1) {
        name = 'FloodView'
      } else if (flag == 2) {
        name = 'FireView'
      }
      this.slideIn = false
      setTimeout(() => {
        this.$router.push({ name })
        this.slideIn = true
      }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/style/variables.less');
@headerHeight: 123px;
@pad: 24px;
#xzapp {
  width: 100%;
  height: 100%;
  .position(fixed,0,auto,0,auto,100000);
}
.close-iframe {
  width: 150px;
  height: 40px;
  line-height: 40px;
  .position(fixed,28px,auto,29px,auto,100001);
  background: linear-gradient(to top, rgba(67, 186, 252, 0.68), rgba(37, 104, 163, 0.29));
  border-radius: 10px;
  letter-spacing: 1px;
  text-shadow: 0 0 4px #10faff;
  &:hover {
    background: linear-gradient(to top, rgba(67, 186, 252, 0.98), rgba(37, 104, 163, 0.29));
  }
}
.layout {
  display: grid;
  grid-template-columns: 30% 1fr 30%;
  background: #070707;
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url('@/assets/image/background2.png') no-repeat;
  background-size: 100% 100%;
  // background: #172d4e;
  .slider {
    position: relative;
    display: flex;
    left: -100%;
    transition: all 0.3s linear;
    height: calc(100vh - @headerHeight + 33px);
    margin-left: 10px;
    padding: 60px 0 60px 40px;
    box-sizing: border-box;
    margin-top: -40px;
    z-index: 1;
    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 103%;
      .position(absolute,-2%,auto,0,auto,-1);
      background: url('@/assets/image/left-bor2.png') no-repeat;
      background-size: 100% 100%;
      background-position: left center;
    }
    &.slideIn {
      left: 0;
    }
    &.right {
      grid-area: 2 / 3 / span 2 / span 1;
      left: unset;
      right: -100%;
      margin-left: unset;
      margin-right: 10px;
      padding: 60px 40px 60px 0;
      &.slideIn {
        right: 0;
      }
      &::before {
        left: auto;
        right: 0;
        transform: rotateY(180deg);
      }
    }
  }
  &-bottom {
    box-sizing: border-box;
    aspect-ratio: 1572 / 130;
    background: url('@/assets/image/bottom-bg.png') no-repeat;
    background-size: 100% 100%;
    .position(absolute,auto,-2px,50%);
    width: 40%;
    transform: translateX(-50%);
    padding: 0 1%;
    &__wrap {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 0;
    }
    .btn-item {
      aspect-ratio: 270 / 115;
      position: relative;
      .flex(row,center, center);
      gap: 12px;
      cursor: pointer;
      font-weight: bold;
      font-size: 30px;
      &.border {
        &::before {
          content: '';
          .position(absolute,0,0,auto,0);
          margin: auto;
          width: 11px;
          height: 110px;
          background: url('@/assets/svg/layout/bottom_item_border.svg') no-repeat;
        }
      }
      &.active {
        background: url('@/assets/image/bottom_item_active.png') no-repeat;
        background-size: 100% 100%;
        &::before {
          background-image: none;
        }
        &::after {
          content: '';
          .position(absolute,auto,15px,50%);
          transform: translateX(-50%);
          width: 60px;
          height: 25px;
          background: url('@/assets/svg/layout/bottom_item_active2.svg') no-repeat;
          animation: iconflash 2s linear infinite;
        }
        label {
          background-image: var(--layoutBottomTextColorA);
        }
      }
      svg {
        width: 32px;
        height: 32px;
      }
      svg,
      label {
        background-image: var(--layoutBottomTextColor);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
      .flag-area {
        .position(absolute,0,auto,0);
        transform: translateY(-140px);
        width: 100%;
        height: 130px;
        .flex(column,space-around, center);
        &.three {
          transform: translateY(-210px);
          height: 200px;
        }
        .flag {
          position: relative;
          width: 100%;
          height: 61px;
          line-height: 61px;
          font-size: 24px;
          letter-spacing: 2px;
          background-image: var(--layoutBottomTextColor2);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          &::before {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            .position(absolute,0,auto,0);
            background: url('@/assets/svg/layout/bottom_flag.svg') no-repeat;
          }
          &.activeFlag {
            &::before {
              background: url('@/assets/svg/layout/bottom_flag_active.svg') no-repeat;
            }
          }
        }
      }
    }
  }
  &-header {
    grid-area: auto / span 3;
    .flex(row,space-around, center);
    gap: 100px;
    height: @headerHeight;
    position: relative;
    background: url('@/assets/image/header-bg.png') no-repeat;
    background-size: 100% 100%;
    aspect-ratio: 3840 / 123;
    width: 100%;
    z-index: 1;
    .title-wrap {
      position: relative;
      padding: 0 @pad;
      display: flex;
      place-items: center;
      height: 100%;
    }
    .title {
      font-size: 60px;
      margin-bottom: 30px;
      user-select: none;
      position: relative;
      background-image: var(--layoutTopTextColor);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 5px;
    }
    .header-side {
      width: 500px;
      margin-top: -40px;
      position: relative;
      &.right,
      &.left {
        font-size: 22px;
        font-weight: 400;
        color: #cae2fb;
        line-height: 25px;
        letter-spacing: 1px;
        .select-box {
          margin-right: 20px;
          .flex(row,flex-end, start);
        }
      }
    }
  }
}
@keyframes iconflash {
  0% {
    margin-bottom: 0;
  }
  25% {
    margin-bottom: 5px;
  }
  50% {
    margin-bottom: 0;
  }
  75% {
    margin-bottom: -5px;
  }
  100% {
    margin-bottom: 0;
  }
}
.background-effect {
  width: 100%;
  height: 100%;
  .position(fixed,0,auto,0,auto,0);
  .flashing-line {
    width: 1500px;
    height: 100%;
    background: -webkit-linear-gradient(left, rgb(2, 20, 48), #01defd, rgb(2, 20, 48));
    background: -moz-linear-gradient(left, rgb(2, 20, 48), #01defd, rgb(2, 20, 48));
    position: relative;
    top: 0;
    transform: skewX(45deg);
    animation: flashing 3s linear infinite;
  }
  @keyframes flashing {
    0% {
      left: 0;
    }
    100% {
      left: 100%;
    }
  }
}
</style>
