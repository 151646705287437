<template>
  <div class="map-area">
    <div class="map-background" style="width: 100vw; height: 100vh">
      <div class="sky-space">
        <div class="sky-stars"></div>
        <div class="sky-stars"></div>
        <div class="sky-stars"></div>
        <div class="sky-stars"></div>
      </div>
      <div class="sky-space-bg">
        <div class="sky-bg-stars"></div>
        <div class="sky-bg-stars"></div>
        <div class="sky-bg-stars"></div>
        <div class="sky-bg-stars"></div>
      </div>
    </div>
    <div id="mapBox">
      <iframe
        scrolling="no"
        class="map-bg"
        width="100%"
        height="100%"
        :allowtransparency="true"
        frameborder="no"
        id="gisShow"
        name="gisShow"
      >
      </iframe>
      <!-- 地图图例 -->
      <div class="menu">
        <div
          v-for="item in MENU"
          :key="`comm-${item.class}`"
          class="menu-btn"
          :class="mapClickActive.includes(item.class) ? 'active' : ''"
          @click="mapClick(item.class)"
        >
          <svg-icon :icon-class="`float-${item.class}`" />
          <div class="right">
            {{ item.label }}
            <span v-if="item.label != '清空图层'">：</span>
            <span class="num" v-if="item.num && item.label != '清空图层'">{{ item.num }}<span v-show="item.num && item.total"> / </span></span>
            <span v-if="item.label != '清空图层'">{{ item.total }}</span>
          </div>
        </div>
      </div>

      <!-- 视频播放弹窗 -->
      <el-dialog
        :title="videoTitle"
        :visible.sync="videoDialogVisible"
        width="920px"
        center
        :modal="false"
        class="detail-dialog"
        :append-to-body="true"
        @close="stopVideo"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="video-area">
          <video
            id="base-video"
            ref="videoElement"
            autoplay
            controls
            muted
            style="height: 100%; width: 100%; object-fit: fill"
          ></video>
        </div>
      </el-dialog>

      <!-- 框选询问弹窗 -->
      <div v-show="filterDialogVisible" class="filter-mask">
        <div class="filter-dialog" :style="{ top: mouseData.top, left: mouseData.left }">
          <div class="filter-close" @click="filterDialogVisible = false">
            <i class="el-icon-close" />
          </div>
          <div class="filter-area">
            <div class="text" @click="addWheelPatrolList">添加到轮巡</div>
          </div>
        </div>
      </div>

      <!-- 人员转移点弹窗 -->
      <el-dialog
        title="转移点详情"
        :visible.sync="zhuanyiDialogVisible"
        width="420px"
        append-to-body
        custom-class="zhuanyi-detail"
        class="detail-dialog"
      >
        <div class="">
          <div>地点：{{ zhuanyiDetail.name }}</div>
          <div>可容纳人数：{{ zhuanyiDetail.peopleNum }}</div>
          <div>转移点类型：{{ zhuanyiDetail.type }}</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import { mapGetters } from 'vuex'
import personIcon from '@/assets/image/position/person.png'
import personActionIcon from '@/assets/image/position/personAction.png'
import goodsIcon from '@/assets/image/position/goods.png'
import refugeIcon from '@/assets/image/position/refuge.png'
import videoIcon from '@/assets/image/position/videoIcon1.png'
import warningIcon from '@/assets/image/position/warning.png'
import communityIcon from '@/assets/image/position/community.png'
import fireIcon from '@/assets/image/position/fire.png'
import machineIcon from '@/assets/image/position/machine.png'
import floodPowerIcon from '@/assets/image/position/floodPower.png'
import {
  COMMUNITY_AREA,
  COMMUNITY_AREA_COLOR,
  MAP_MENU,
  HeatMapData,
  ZHUANYI_DATA,
  CANGKU_DATA,
  WATER_DATA,
  JIANCE_LIST
} from './data.js'
import flvjs from 'flv.js'
import { INIT_LIST } from '@/utils/videoData'
import { getTelHLS } from '@/api/video'
import { FLOOD_POWER_DATA_NEW } from './data_update'

var bridge = null
export default {
  name: 'MapPart',
  data() {
    return {
      MENU: [],
      cdmap: null,
      mapClickActive: [],
      argData: null,
      videoDialogVisible: false,
      videoTitle: '',
      myVideo: null,
      filterDialogVisible: false,
      drawGraphicData: {},
      mouseData: { left: '50%', top: '50%' },
      timer: null,
      zhuanyiDialogVisible: false,
      zhuanyiDetail: {
        name: '',
        peopleNum: '',
        type: ''
      }
    }
  },
  computed: {
    ...mapGetters(['mapPointData', 'mapFilterData'])
  },
  watch: {
    $route: {
      handler(val) {
        // 地图图例数据
        let menu = MAP_MENU[val.name] || []
        this.MENU = [...menu]
        // 热力图-防汛防台和城市安全需热力图
        this.cdmap &&
          this.cdmap.Invoke({
            ActionName: 'Clear'
          })
        if (val.name == 'FireView') {
          this.timer = setInterval(() => {
            if (this.cdmap) {
              this.setHeatMapFun(HeatMapData)
              clearInterval(this.timer)
            }
          }, 1000)
        } else {
          this.timer && clearInterval(this.timer)
        }
      },
      immediate: true,
      deep: true
    },
    filterDialogVisible(val) {
      if (!val) this.$store.dispatch('mapAction/mapFilterData', '')
    },
    // 监听框选动作
    mapFilterData(val) {
      if (!val) {
        // 去除上一次的过滤（框选）事件
        this.cdmap &&
          this.cdmap.Invoke({
            ActionName: 'Clear'
          })
        return
      }
      this.cdmap.Invoke({
        ActionName: 'DrawAddFilter',
        Parameters: {
          type: 'circle',
          isfilter: true,
          filterStyle: {
            pointSymbol: {
              type: 'simple-marker',
              style: 'circle',
              color: [58, 208, 245, 0.4],
              size: '0px',
              outline: {
                color: [171, 177, 255],
                width: 0
              }
            },
            lineSymbol: {
              type: 'simple-line',
              color: [58, 208, 245, 0.86],
              width: '0px',
              style: 'solid'
            },
            symbol: {
              type: 'simple-fill',
              color: [62, 146, 220, 0.1],
              style: 'solid',
              outline: {
                color: [75, 250, 255, 0.7],
                width: 2
              }
            },
            fontSymbol: {
              type: 'text',
              color: [255, 255, 255, 1],
              xoffset: 3,
              yoffset: 3,
              font: {
                size: 30,
                weight: 'bold'
              }
            }
          }
        }
      })
    },
    mapPointData(val) {
      const data = JSON.parse(val)
      const setMapSiteData = data.detailData
      const commonFlag = data.name
      let fontName
      let rendererData
      this.clearSingleFun('person_layer')
      this.clearSingleFun('other_layer')
      this.clearSingleFun('fire_layer')
      this.clearSingleFun('machine_layer')
      this.clearSingleFun('goods_layer')
      this.clearSingleFun('refuge_layer')
      this.clearSingleFun('floodPower_layer')
      this.clearSingleFun('floodPower_area0')
      this.clearSingleFun('floodPower_area1')
      this.clearSingleFun('floodPower_area2')
      this.clearSingleFun('floodPower_area3')
      this.clearSingleFun('floodPower_area4')
      this.clearSingleFun('community_layer')
      this.clearSingleFun('village_layer')
      this.clearSingleFun('warning_layer')
      this.clearSingleFun('goodsp_layer')
      this.clearSingleFun('video_layer')
      this.clearSingleFun('jiance_layer')
      this.clearSingleFun('zhuanyi_layer')
      this.clearSingleFun('cangku_layer')
      this.clearSingleFun('power_layer')
      this.clearSingleFun('power_area0')
      this.clearSingleFun('power_area1')
      this.clearSingleFun('power_area2')
      this.clearSingleFun('power_area3')
      this.clearSingleFun('power_area4')
      this.clearSingleFun('power_area5')
      this.clearSingleFun('power_area6')
      COMMUNITY_AREA.forEach((item) => {
        this.clearSingleFun(`${item.code}_layer`)
      })
      if (commonFlag == 'totalPeople' || commonFlag == 'people') {
        //人员撒点
        fontName = 'person_layer'
        rendererData = {
          label: '应急调度人员',
          url: commonFlag == 'totalPeople' ? personIcon : personActionIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.name'
        }
      } else if (commonFlag == 'other') {
        //其他无数据的撒点
        fontName = 'other_layer'
        rendererData = {
          label: '暂无数据',
          url: refugeIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.name'
        }
      } else if (commonFlag == 'fire') {
        //室外消防栓撒点
        fontName = 'fire_layer'
        rendererData = {
          label: '室外消防栓',
          url: fireIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.name'
        }
      } else if (commonFlag == 'machine') {
        //机器撒点
        fontName = 'machine_layer'
        rendererData = {
          label: '机器',
          url: machineIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.name'
        }
      } else if (commonFlag == 'totalGoods' || commonFlag == 'goods' || commonFlag == 'goodsp') {
        //仓库撒点
        fontName = commonFlag == 'goodsp' ? 'goodsp_layer' : 'goods_layer'
        rendererData = {
          label: '应急物资',
          url: goodsIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.name'
        }
      } else if (commonFlag == 'refuge') {
        //避难所撒点
        fontName = 'refuge_layer'
        rendererData = {
          label: '避难所',
          url: refugeIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.refugeName'
        }
      } else if (commonFlag == 'floodPower') {
        //抢险力量
        fontName = 'floodPower_layer'
        rendererData = {
          label: '抢险力量',
          url: floodPowerIcon,
          width: '37px',
          height: '46.5px',
          fields: '#.name'
        }
        this.setAreaFun({ ...setMapSiteData[0], fontName: 'floodPower_area0' })
        this.changeMapCenter(setMapSiteData[0].x, setMapSiteData[0].y)
      } else if (commonFlag == 'powerNew') {
        //抢险力量
        fontName = 'power_layer'
        rendererData = {
          label: '抢险力量',
          url: floodPowerIcon,
          width: '37px',
          height: '46.5px',
          fields: '#.name'
        }
        FLOOD_POWER_DATA_NEW.forEach((item, index) => {
          this.setAreaFun({ ...item, fontName: 'power_area' + index })
        })
      } else if (commonFlag == 'community' || commonFlag == 'village') {
        //小区/村撒点
        fontName = commonFlag == 'community' ? 'community_layer' : 'village_layer'
        rendererData = {
          label: '小区',
          url: communityIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.name'
        }
      } else if (commonFlag == 'warning') {
        //报警撒点
        fontName = 'warning_layer'
        rendererData = {
          label: '报警',
          url: warningIcon,
          width: '92px',
          height: '94px',
          fields: '#.name'
        }
      } else if (commonFlag == 'video') {
        //视频撒点
        fontName = 'video_layer'
        rendererData = {
          label: '视频',
          url: videoIcon,
          width: '43px',
          height: '47px',
          fields: '#.name'
        }
      } else if (commonFlag == 'jiance') {
        //防台防汛的视频监控撒点
        fontName = 'jiance_layer'
        rendererData = {
          label: '监控',
          url: videoIcon,
          width: '43px',
          height: '47px',
          fields: '#.name'
        }
      } else if (commonFlag == 'baseInfo') {
        this.changeMapCenter('4385.441368963452', '-20638.786524233987')
        return
      } else if (commonFlag == 'zhuanyi') {
        //人员转移点撒点
        fontName = 'zhuanyi_layer'
        rendererData = {
          label: '人员转移点',
          url: communityIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.name'
        }
      } else if (commonFlag == 'cangku') {
        //应急抢险物资仓库撒点
        fontName = 'cangku_layer'
        rendererData = {
          label: '应急抢险物资仓库',
          url: goodsIcon,
          width: '36.5px',
          height: '46.5px',
          fields: '#.name'
        }
      }
      this.setPointFun(setMapSiteData, fontName, rendererData)
    }
  },
  mounted() {
    if (!this.cdmap) {
      this.initGisMap()
    }
  },
  methods: {
    //初始化
    async initGisMap() {
      try {
        var _that = this
        bridge = await new window.CityGis.Bridge({
          id: 'gisShow',
          url: 'http://32.1.8.189/citygis/areamap/WidgetPages/WidgetGIS.html?code=1215&themeid=Gis&devicetype=lg&mapType=3d',
          onReady: function () {
            //定位闪烁
            //地图加载完后，初始化自动移动到某一个位置
            bridge.Invoke({
              ActionName: 'goToPosition',
              Parameters: {
                positon: {
                  x: '4385.441368963452',
                  y: '-20638.786524233987'
                },
                hasImg: false,
                hasvideo: false,
                zoom: 4,
                isRotation360: false
              }
            })
          }
        })
        _that.cdmap = bridge
        //鼠标操作地图回发消息处理
        bridge.addEventListener((arg) => {
          switch (arg.action) {
            case 'mapclick':
              console.log('点击触发:', arg.data)
              if (arg.data.refuge_layer) {
                //避难所点
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.refugeDialogFun()
              } else if (arg.data.floodPower_layer) {
                //抢险力量点
                let flagName = 'floodPower_layer'
                _that.argData = arg.data[flagName][0]
                _that.floodPowerDialogFun()
              } else if (arg.data.goods_layer) {
                //应急物资仓库点
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.goodsDialogFun()
              } else if (arg.data.community_layer) {
                //小区点位
              } else if (arg.data.village_layer) {
                //村居点位
              } else if (arg.data.video_layer) {
                // 视频点位
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.videoDialogFun('video_layer')
              } else if (arg.data.jiance_layer) {
                // 视频监控点位
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.videoDialogFun('jiance_layer')
              } else if (arg.data.zhuanyi_layer) {
                // 人员转移点位
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.zhuanyiDialogFun()
              } else if (arg.data.cangku_layer) {
                // 应急抢险仓库点位
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.cangkuDialogFun()
              }

              break
            case 'getPoint':
              //地图点选消息
              _that.nowPoint = [arg.data['坐标X'], arg.data['坐标Y']]
              _that.breathPoint(arg.data['坐标X'], arg.data['坐标Y'])
              break
            case 'ResetMap':
              //地图重置完成消息
              break
            case 'changeTheme':
              //地图主题切换消息
              break
            case 'Clear':
              //地图清空消息
              break
            // 筛选
            case 'DrawGraphic':
              console.log('框选完成触发:', arg)
              // 弹窗询问
              _that.drawGraphicData = arg.data
              _that.filterDialogVisible = true
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    //地图图例事件
    mapClick(name) {
      if (this.mapClickActive.includes(name)) {
        this.mapClickActive.splice(this.mapClickActive.indexOf(name), 1)
      } else {
        this.mapClickActive.push(name)
        if (name == 'video') {
          let a = {}
          a.name = 'video'
          a.detailData = [...INIT_LIST]
          a.date = new Date().valueOf()
          this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
        } else if (name == 'jiance') {
          let a = {}
          a.name = 'jiance'
          a.detailData = [...JIANCE_LIST]
          a.date = new Date().valueOf()
          this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
        } else if (name == 'danbin') {
          let a = {}
          a.name = 'totalPeople'
          a.detailData = [
            {
              x: '4385.441368963452',
              y: '-20638.786524233987',
              name: '李翔'
            },
            {
              x: '3914.3237224226',
              y: '-19053.3493558921',
              name: '李明'
            },
            {
              x: '7402.4589748742',
              y: '-17574.8421829216',
              name: '吴霞'
            },
            {
              x: '2837.9837149295',
              y: '-23308.0438409738',
              name: '张远'
            },
            {
              x: '7023.150903248191',
              y: '-18151.54105959082',
              name: '钱多多'
            }
          ]
          a.date = new Date().valueOf()
          this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
        } else if (name == 'yingyan' || name == 'car') {
          let a = {}
          a.name = 'video'
          a.detailData = [{ ...INIT_LIST[2] }]
          a.date = new Date().valueOf()
          this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
        } else if (
          name == 'service' ||
          name == 'other' ||
          name == 'water2' ||
          name == 'keran' ||
          name == 'fire' ||
          name == 'xiali' ||
          name == 'menjin'
        ) {
          let detailData = [
            {
              x: '4385.441368963452',
              y: '-20638.786524233987',
              name: ''
            },
            {
              x: '3914.3237224226',
              y: '-19053.3493558921',
              name: ''
            },
            {
              x: '7402.4589748742',
              y: '-17574.8421829216',
              name: ''
            },
            {
              x: '7023.150903248191',
              y: '-18151.54105959082',
              name: ''
            }
          ]
          let a = {}
          if (name == 'fire') {
            a.name = 'fire'
          } else if (name == 'service' || name == 'other' || name == 'keran') {
            a.name = 'machine'
          } else {
            a.name = 'other'
          }
          a.detailData = detailData
          a.date = new Date().valueOf()
          this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
        } else if (name == 'zhuanyi') {
          let a = {}
          a.name = 'zhuanyi'
          a.detailData = [...ZHUANYI_DATA]
          a.date = new Date().valueOf()
          this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
        } else if (name == 'cangku') {
          let a = {}
          a.name = 'cangku'
          a.detailData = [...CANGKU_DATA]
          a.date = new Date().valueOf()
          this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
        } else if (name == 'community') {
          COMMUNITY_AREA.forEach((item, index) => {
            this.setVillage(item.code, COMMUNITY_AREA_COLOR[index])
          })
        } else if (name == 'power') {
          let a = {}
          a.name = 'powerNew'
          a.detailData = FLOOD_POWER_DATA_NEW
          a.date = new Date().valueOf()
          this.$store.dispatch('mapAction/mapPointData', JSON.stringify(a))
        } else if (name == 'water') {
          this.cdmap &&
            this.cdmap.Invoke({
              ActionName: 'Clear'
            })
          this.setHeatMapFun(WATER_DATA)
        } else if (name == 'close') {
          this.mapClickActive = []
          this.cdmap.Invoke({
            ActionName: 'ResetMap'
          })
        }
      }
    },
    // 显示居委 小区区域
    setVillage(code, itemColor) {
      let layers = {
        name: '村居委会边界',
        where: `居委会代码='${code}'`
      }
      let color = itemColor.color
      let lineColor = itemColor.lineColor
      let fields = ['#.居委会名称']
      let flagName = `${code}_layer`
      this.cdmap.Invoke({
        ActionName: 'ShowData',
        Parameters: {
          name: flagName,
          type: 'layer',
          isLocate: false,
          data: { layers },
          isHighlight: false,
          popupEnabled: false,
          labels: [
            {
              fields: fields,
              color: [66, 157, 225, 1],
              size: 11
            }
          ],
          legendVisible: false,
          renderer: {
            type: 'simple',
            symbol: {
              type: 'polygon-3d',
              symbolLayers: [
                {
                  type: 'fill',
                  material: {
                    color: color
                  },
                  outline: {
                    color: lineColor,
                    size: 2
                  }
                }
              ]
            }
          }
        }
      })
    },
    //通用撒点
    setPointFun(data, fontName, rendererData) {
      this.cdmap.Invoke({
        ActionName: 'ShowData',
        Parameters: {
          name: fontName,
          data: {
            content: data,
            parsedata: 'function(d){return d}',
            parsegeometry: 'function(item){return {x:item.x,y:item.y}}'
          },
          legendVisible: false,
          popupEnabled: false,
          isLocate: false, //地图是否自适应
          renderer: {
            type: 'simple',
            label: rendererData.label,
            visualVariables: [],
            symbol: {
              type: 'picture-marker',
              url: rendererData.url,
              width: rendererData.width,
              height: rendererData.height
            }
          },
          // "maxScale":100,
          // "minScale":5000,
          labels: [
            {
              fields: rendererData.fields,
              type: 'text',
              color: [75, 250, 255, 1],
              font: {
                family: 'Arial Unicode MS',
                size: 22,
                weight: 'bold'
              },
              haloColor: [255, 255, 255, 1],
              haloSize: 1,
              maxScale: 100,
              minScale: 99999,
              labelPlacement: fontName == 'building_layer' ? 'above-center' : 'below-center'
            }
          ]
        }
      })
    },
    //通用画面
    setAreaFun(data) {
      this.cdmap.Invoke({
        ActionName: 'ShowData',
        Parameters: {
          name: data.fontName,
          type: 'polygon',
          legendVisible: false,
          popupEnabled: false,
          data: {
            content: [
              {
                geometry: {
                  rings: data.rings
                }
              }
            ],
            parsedata: 'function(d){return d}',
            parsegeometry: 'function(item){return {rings:item.geometry.rings}}'
          },
          renderer: {
            type: 'simple',
            label: '救援区域',
            visualVariables: [],
            symbol: {
              type: 'polygon-3d',
              symbolLayers: [
                {
                  type: 'fill',
                  material: {
                    color: data.color
                  }
                }
              ]
            }
          }
        }
      })
    },
    //热力图
    setHeatMapFun(data) {
      this.cdmap.Invoke({
        ActionName: 'doShowHeat',
        Parameters: {
          datasource: data,
          postUrl: '',
          geofield: {
            xfield: 'x',
            yfield: 'y'
          },
          visible: true,
          weightFied: 'count',
          gradient: {
            '.25': '#4ef1b2',
            '.50': '#7cd346',
            '.75': '#f3f12c',
            '.95': '#fd2f02'
          },
          radius: 50
        }
      })
    },
    //清空单个标点
    clearSingleFun(name) {
      this.cdmap.Invoke({
        ActionName: 'ShowData',
        Parameters: {
          name,
          mode: 'delete'
        }
      })
    },
    // 更新地图中心点
    changeMapCenter(sg_x, sg_y) {
      this.cdmap.Invoke({
        ActionName: 'goToPosition',
        Parameters: {
          positon: {
            x: sg_x,
            y: sg_y
          },
          heading: 0,
          tilt: 0, // 倾斜角度,不倾斜设置为0,范围:0-90
          hasImg: false,
          zoom: 4, // 缩放级别,范围:0-11
          isRotation360: false
        }
      })
    },
    //视频弹窗
    async videoDialogFun(layer = 'video_layer') {
      Message({
        message: '正在拉取视频,请耐心等待几秒钟',
        type: 'info',
        duration: 3000
      })
      this.videoTitle = this.argData.name
      this.videoDialogVisible = true

      // if (layer == 'video_layer') {
      this.$store.dispatch('video/getVideoHlsAction', {
        hls: this.argData.hls,
        cb: (res) => {
          this.playerStartFlv(res)
        }
      })
      // } else {
      //   getTelHLS({ channelId: this.argData.hls })
      //     .then((res) => {
      //       if (res.result == 0) {
      //         this.playerStartFlv(res.url)
      //       }
      //     })
      //     .catch(() => {
      //       console.log('接口有误')
      //     })
      // }
    },
    // fls处理视频
    playerStartFlv(url) {
      var videoElement = document.getElementById('base-video')
      this.myVideo = flvjs.createPlayer(
        {
          type: 'flv',
          isLive: true,
          hasAudio: false,
          url
        },
        {
          enableWorker: false, //不启用分离线程
          enableStashBuffer: false, //关闭IO隐藏缓冲区
          reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
          autoCleanupSourceBuffer: true //自动清除缓存
        }
      )
      this.myVideo.attachMediaElement(videoElement)
      this.myVideo.load()
      setTimeout(() => {
        this.myVideo.play()
      }, 200)
    },
    stopVideo() {
      if (this.myVideo) {
        this.myVideo.pause()
        this.myVideo.unload()
        this.myVideo.detachMediaElement()
        this.myVideo.destroy()
        this.myVideo = null
      }
    },
    //避难所点位---弹窗
    refugeDialogFun() {
      let b = {}
      b.name = 'refuge'
      b.detail = this.argData
      b.isTrue = false
      b.date = new Date().valueOf()
      this.$store.dispatch('dialogAction/dialogFlagDic', JSON.stringify(b))
    },
    //抢险力量---弹窗
    floodPowerDialogFun() {
      let b = {}
      b.name = 'floodPower'
      b.detail = this.argData
      b.isTrue = false
      b.date = new Date().valueOf()
      this.$store.dispatch('dialogAction/dialogFlagDic', JSON.stringify(b))
    },
    //物资点位---弹窗
    goodsDialogFun() {
      let b = {}
      b.name = 'goods'
      b.detail = this.argData
      b.isTrue = false
      b.date = new Date().valueOf()
      this.$store.dispatch('dialogAction/dialogFlagDic', JSON.stringify(b))
    },
    // 人员转移点---弹窗+视频切换
    zhuanyiDialogFun() {
      if (this.argData.videoList.length) {
        let data = {}
        data.videoList = this.argData.videoList
        data.date = new Date().valueOf()
        this.$store.dispatch('mapAction/mapVideoListForEqu', JSON.stringify(data))
      } else {
        Message({
          message: '提示：该点位暂无实时视频',
          type: 'info',
          duration: 3000
        })
      }
      this.zhuanyiDetail = this.argData
      this.zhuanyiDialogVisible = true
    },
    // 应急抢险仓库点位---视频切换
    cangkuDialogFun() {
      if (this.argData.videoList.length) {
        let data = {}
        data.videoList = this.argData.videoList
        data.date = new Date().valueOf()
        this.$store.dispatch('mapAction/mapVideoListForEqu', JSON.stringify(data))
      } else {
        Message({
          message: '提示：该点位暂无实时视频',
          type: 'info',
          duration: 3000
        })
      }
    },
    // 用户点击加入轮巡
    addWheelPatrolList() {
      // 1-传框选区域坐标this.drawGraphicData给后端分析，拿到符合的具体点位res
      // 2-调接口传res
      // 3-刷新轮巡列表videoList
      let videoList = [
        {
          videoSrc: '',
          name: '新汇绿苑一村东区垃圾房',
          hls: 'qP5GKuIrB1EJB3JT8DIROV',
          x: '6185.226699370483',
          y: '-19267.41636532593'
        },
        {
          videoSrc: '',
          name: '革新村徐家宅垃圾厢房',
          hls: 'qP5GKuIrB1EJB3JSMEC6JM',
          x: '6494.74012802376',
          y: '-18365.16165870723'
        }
      ]
      this.$store.dispatch('mapAction/mapFilterVideoData', JSON.stringify(videoList))
      setTimeout(() => {
        this.filterDialogVisible = false
      }, 2000)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/style/variables.less');

.map-area {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}
#mapBox {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}
.video-area {
  width: 100%;
  height: 500px;
  padding-left: 20px;
}
.map-background {
  // background-image: linear-gradient(270deg, #020e13 0%, rgba(6, 29, 49, 0.37) 100%, #021217 100%);
  // background-image: radial-gradient(#03132e, #020a1d);
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden !important;
  flex: auto !important;
  flex-direction: column !important;

  .sky-space-bg {
    .position(absolute,36vh,auto,42vw);
    height: 16vw;
    width: 16vw;
  }
  .sky-bg-stars {
    .position(absolute,0,0,0,0);
    overflow: hidden;
    background-image: radial-gradient(1px 2px at 50px 50px, #eee, rgba(0, 0, 0, 0)),
      radial-gradient(2px 3px at 20px 35px, #fff, rgba(0, 0, 0, 0)),
      radial-gradient(3px 3px at 60px 20px, #ddd, rgba(0, 0, 0, 0));
    background-repeat: repeat;
    background-size: 200px 200px;
    opacity: 0.2;
    animation: opacity 8s infinite;
  }
  .sky-bg-stars:nth-child(1) {
    background-position: 0% 90%;
    animation-delay: 0s;
  }
  .sky-bg-stars:nth-child(2) {
    background-position: 50% 10%;
    animation-delay: 0.6s;
  }
  .sky-bg-stars:nth-child(3) {
    background-position: 40% -80%;
    background-size: 120px 200px;
    animation-delay: 1.8s;
  }
  .sky-bg-stars:nth-child(4) {
    background-position: 150% -80%;
    background-size: 220px 100px;
    animation-delay: 3.2s;
  }
  .sky-space,
  .sky-stars {
    .position(absolute,0,0,1vw,1vw);
    overflow: hidden;
  }
  .sky-stars {
    background-image: radial-gradient(
        2px 2px at 50px 200px,
        rgba(255, 255, 255, 0.1),
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(3px 3px at 40px 60px, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0)),
      radial-gradient(4px 5px at 100px 30px, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0));
    background-repeat: repeat;
    background-size: 380px 380px;
    opacity: 0;
    animation-name: zoom;
    animation-delay: 0s;
    animation-duration: 10s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
  .sky-stars:nth-child(1) {
    top: 20vh;
    bottom: 20vh;
    left: 10vw;
    right: 10vw;
    background-size: 120px 120px;
    background-position: 10% 90%;
  }
  .sky-stars:nth-child(2) {
    background-position: 20% 50%;
    animation-delay: 0.3s;
  }
  .sky-stars:nth-child(3) {
    background-position: 40% 20%;
    animation-delay: 1.3s;
  }
  .sky-stars:nth-child(4) {
    background-position: 50% 10%;
    background-size: 200px 200px;
    transform: rotate(60deg);
    animation-delay: 2.1s;
  }
  .sky-stars:nth-child(5) {
    background-position: 30% 30%;
    background-size: 120px 270px;
    animation-delay: 3s;
  }
  .sky-stars:nth-child(6) {
    background-position: 50% 20%;
    animation-delay: 5.5s;
  }
}
@keyframes opacity {
  0% {
    opacity: 0.2;
    transform: rotate(-5deg);
    animation-timing-function: ease-in;
  }
  50% {
    opacity: 0.8;
    transform: rotate(-13deg);
    animation-timing-function: ease-in;
  }
  100% {
    opacity: 0.1;
    transform: rotate(-20deg);
    animation-timing-function: ease-in;
  }
}
@keyframes zoom {
  0% {
    opacity: 0.02;
    transform: scale(0.1);
    transform: rotate(-20deg);
    animation-timing-function: ease-in;
  }
  5% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.3;
    transform: scale(1.8);
  }
  100% {
    opacity: 0.1;
    transform: scale(2.2);
  }
}

.filter-mask {
  .position(fixed,0,auto,0,auto,100000);
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  .filter-dialog {
    position: absolute;
    width: 150px;
    height: 80px;
    background: url('@/assets/image/dialog-bg.png') no-repeat;
    background-size: 100% 100%;
    .filter-close {
      width: 20px;
      height: 20px;
      font-size: 20px;
      .position(absolute,5px,auto,auto,5px);
      line-height: 1;
      cursor: pointer;
      &:hover {
        color: #2eb8ff;
      }
    }
    .filter-area {
      width: 100%;
      height: 100%;
      padding: 0 5px 0 8px;
      .text {
        background: rgba(17, 58, 109, 0.7);
        line-height: 36px;
        margin-top: 25px;
        cursor: pointer;
        &:hover {
          color: #2eb8ff;
        }
      }
    }
  }
}

:deep(.el-dialog.zhuanyi-detail) {
  .el-dialog__body {
    font-size: 16px;
    letter-spacing: 1px;
    padding-left: 40px;
    font-weight: 500;
    color: #ffffff;
    line-height: 34px;
  }
}
</style>
