import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/common.less'
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/element-ui.less'
import * as Echarts from 'echarts'
import { get, isEmpty } from 'lodash'
import '@/icons'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import '@/style/theme.css'
import '@/directives/el-draggable-dialog'
import directive from './directives/index'
Vue.config.productionTip = false
import infiniteScroll from 'vue-infinite-scroll'
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '3aaff3b0ba41ba7ac1ef4932e16991c4',
  plugin: [
    'AMap.Autocomplete',
    'AMap.Geocoder',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.Marker',
    'AMap.MapType',
    'AMap.AMapManager',
    'AMap.Geolocation',
    'AMap.CircleEditor'
  ],
  v: '1.4.4'
})
Vue.use(infiniteScroll)
Vue.use(elementUI)
Vue.use(VueAwesomeSwiper)
// 引入指令
Vue.use(directive)
Vue.prototype.$echarts = Echarts
Vue.prototype.$mainColor = '#e1ebff'
Vue.prototype.$colorSet = ['#3DCBF1', '#FFD24A', '#EC6666', '#44A5FF']
Vue.prototype.$get = get
Vue.prototype.$isEmpty = isEmpty

const baseURL = 'https://api.saas.famesmart.com'
Vue.filter('getImg', function (uuid) {
  if (!uuid) {
    return ''
  }
  if (uuid && (uuid.startsWith('http') || uuid.startsWith('data:image'))) {
    return uuid
  }
  return `${baseURL}/resident-manager/download_self?uuid=${uuid}`
})

// 刷新页面-重新获取部门列表
// if (sessionStorage['saas-token']) {
store.dispatch('user/getApartmentList')
// }

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
