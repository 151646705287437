/* eslint-disable */
var CalendarData = new Array(100)
var madd = new Array(12)
var numString = '一二三四五六七八九十'
var monString = '正二三四五六七八九十冬腊'
var cYear, cMonth, cDay, TheDate

// 农历每月仅仅能是29或30天，一年用12(或13)个二进制位表示，从高到低，相应位为1表示30天，否则29天
CalendarData = new Array(
  0xa4b,
  0x5164b,
  0x6a5,
  0x6d4,
  0x415b5,
  0x2b6,
  0x957,
  0x2092f,
  0x497,
  0x60c96,
  0xd4a,
  0xea5,
  0x50da9,
  0x5ad,
  0x2b6,
  0x3126e,
  0x92e,
  0x7192d,
  0xc95,
  0xd4a,
  0x61b4a,
  0xb55,
  0x56a,
  0x4155b,
  0x25d,
  0x92d,
  0x2192b,
  0xa95,
  0x71695,
  0x6ca,
  0xb55,
  0x50ab5,
  0x4da,
  0xa5b,
  0x30a57,
  0x52b,
  0x8152a,
  0xe95,
  0x6aa,
  0x615aa,
  0xab5,
  0x4b6,
  0x414ae,
  0xa57,
  0x526,
  0x31d26,
  0xd95,
  0x70b55,
  0x56a,
  0x96d,
  0x5095d,
  0x4ad,
  0xa4d,
  0x41a4d,
  0xd25,
  0x81aa5,
  0xb54,
  0xb6a,
  0x612da,
  0x95b,
  0x49b,
  0x41497,
  0xa4b,
  0xa164b,
  0x6a5,
  0x6d4,
  0x615b4,
  0xab6,
  0x957,
  0x5092f,
  0x497,
  0x64b,
  0x30d4a,
  0xea5,
  0x80d65,
  0x5ac,
  0xab6,
  0x5126d,
  0x92e,
  0xc96,
  0x41a95,
  0xd4a,
  0xda5,
  0x20b55,
  0x56a,
  0x7155b,
  0x25d,
  0x92d,
  0x5192b,
  0xa95,
  0xb4a,
  0x416aa,
  0xad5,
  0x90ab5,
  0x4ba,
  0xa5b,
  0x60a57,
  0x52b,
  0xa93,
  0x40e95
)
var solarTerm = new Array(
  '小寒',
  '大寒',
  '立春',
  '雨水',
  '惊蛰',
  '春分',
  '清明',
  '谷雨',
  '立夏',
  '小满',
  '芒种',
  '夏至',
  '小暑',
  '大暑',
  '立秋',
  '处暑',
  '白露',
  '秋分',
  '寒露',
  '霜降',
  '立冬',
  '小雪',
  '大雪',
  '冬至'
)

madd[0] = 0
madd[1] = 31
madd[2] = 59
madd[3] = 90
madd[4] = 120
madd[5] = 151
madd[6] = 181
madd[7] = 212
madd[8] = 243
madd[9] = 273
madd[10] = 304
madd[11] = 334

const date = {
  showTime(style = 1) {
    let vWeek, vWeek_s
    vWeek = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = date.getDate()
    d = d < 10 ? '0' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    minute = minute < 10 ? '0' + minute : minute
    let Seconds = date.getSeconds()
    Seconds = Seconds < 10 ? '0' + Seconds : Seconds
    vWeek_s = date.getDay()
    //document.getElementById("time").innerHTML = year + "年" + month + "月" + day + "天" + "\t" + hours + ":" + minutes +":" + seconds + "\t" + vWeek[vWeek_s] ;
    //let timeStr = y + '年' + m + '月' + d + '天' + ' '  + h + ':' + minute +':' + Seconds + ' ' + vWeek[vWeek_s]
    //console.log(time)
    let calen = this.GetLunarDay(y, m, d)
    let jieqi = this.jieqi(y, parseInt(m), parseInt(d))
    // if (h === '00' && minute === '00' && Seconds === '00'){
    //     this.getTianQi()
    // }
    // var newTime = y + '年' +
    //         m + '月' +
    //         d + '天 ' +
    //         h + ':' +
    //         minute + ':' +
    //         Seconds + ' ' +
    //         vWeek[vWeek_s];
    if (style == 2) {
      return { date: y + '.' + m + '.' + d, time: h + ':' + minute, calen: calen, jieqi: jieqi }
    } else {
      return {
        date: y + '年' + m + '月' + d + '日',
        time: h + ':' + minute + ':' + Seconds,
        week: vWeek[vWeek_s],
        calen: calen,
        jieqi: jieqi
      }
    }
  },
  GetLunarDay(solarYear, solarMonth, solarDay) {
    if (solarYear < 1921 || solarYear > 2020) {
      return ''
    } else {
      solarMonth = parseInt(solarMonth) > 0 ? solarMonth - 1 : 11
      this.e2c(solarYear, solarMonth, solarDay)
      return this.GetcDateString()
    }
  },
  jieqi(yyyy, mm, dd) {
    var sTermInfo = new Array(
      0,
      21208,
      42467,
      63836,
      85337,
      107014,
      128867,
      150921,
      173149,
      195551,
      218072,
      240693,
      263343,
      285989,
      308563,
      331033,
      353350,
      375494,
      397447,
      419210,
      440795,
      462224,
      483532,
      504758
    )
    let solarTerms = ''
    while (solarTerms === '') {
      var tmp1 = new Date(
        31556925974.7 * (yyyy - 1900) +
          sTermInfo[(mm - 1) * 2 + 1] * 60000 +
          Date.UTC(1900, 0, 6, 2, 5)
      )
      var tmp2 = tmp1.getUTCDate()
      if (tmp2 === dd) {
        solarTerms = solarTerm[(mm - 1) * 2 + 1]
        //console.log('1111111111'+ solarTerms)
      }
      tmp1 = new Date(
        31556925974.7 * (yyyy - 1900) + sTermInfo[(mm - 1) * 2] * 60000 + Date.UTC(1900, 0, 6, 2, 5)
      )
      tmp2 = tmp1.getUTCDate()
      if (tmp2 === dd) {
        solarTerms = solarTerm[(mm - 1) * 2]
        //console.log('22222222'+ solarTerms)
      }
      if (dd > 1) {
        dd = dd - 1
      } else {
        mm = mm - 1

        if (mm < 0) {
          yyyy = yyyy - 1
          mm = 11
        }
        dd = 31
      }
    }
    return solarTerms
  },
  e2c() {
    TheDate =
      arguments.length !== 3 ? new Date() : new Date(arguments[0], arguments[1], arguments[2])
    var total, m, n, k
    var isEnd = false
    var tmp = TheDate.getYear()
    if (tmp < 1900) {
      tmp += 1900
    }
    total =
      (tmp - 1921) * 365 +
      Math.floor((tmp - 1921) / 4) +
      madd[TheDate.getMonth()] +
      TheDate.getDate() -
      38

    if (TheDate.getYear() % 4 === 0 && TheDate.getMonth() > 1) {
      total++
    }
    for (m = 0; ; m++) {
      k = CalendarData[m] < 0xfff ? 11 : 12
      for (n = k; n >= 0; n--) {
        if (total <= 29 + this.GetBit(CalendarData[m], n)) {
          isEnd = true
          break
        }
        total = total - 29 - this.GetBit(CalendarData[m], n)
      }
      if (isEnd) break
    }
    cYear = 1921 + m
    cMonth = k - n + 1
    cDay = total
    if (k === 12) {
      if (cMonth === Math.floor(CalendarData[m] / 0x10000) + 1) {
        cMonth = 1 - cMonth
      }
      if (cMonth > Math.floor(CalendarData[m] / 0x10000) + 1) {
        cMonth--
      }
    }
  },
  GetBit(m, n) {
    return (m >> n) & 1
  },
  GetcDateString() {
    var tmp = ''
    // tmp+=tgString.charAt((cYear-4)%10);
    // tmp+=dzString.charAt((cYear-4)%12);
    // tmp+="(";
    // tmp+=sx.charAt((cYear-4)%12);
    // tmp+=")年 ";
    // var sM = ''
    // var sD = ''
    if (cMonth < 1) {
      tmp += '(闰)'
      tmp += monString.charAt(-cMonth - 1)
    } else {
      tmp += monString.charAt(cMonth - 1)
    }
    tmp += '月'
    tmp += cDay < 11 ? '初' : cDay < 20 ? '十' : cDay < 30 ? '廿' : '三十'
    if (cDay % 10 !== 0 || cDay === 10) {
      tmp += numString.charAt((cDay - 1) % 10)
    }
    //sD = numString.charAt((cDay-1)%10);

    return tmp
  }
}

export default date
