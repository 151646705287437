export const POLYGONS = [
  {
    color: '#2b83f9',
    path: [
      [121.345057, 31.12566],
      [121.345969, 31.126092],
      [121.346307, 31.126161],
      [121.347068, 31.126565],
      [121.346999, 31.126872],
      [121.348195, 31.127483],
      [121.349128, 31.127933],
      [121.351435, 31.12909],
      [121.35313, 31.129899],
      [121.353699, 31.129449],
      [121.356982, 31.130927],
      [121.363655, 31.133765],
      [121.365779, 31.134564],
      [121.366241, 31.133838],
      [121.370511, 31.135877],
      [121.372185, 31.136125],
      [121.377388, 31.137053],
      [121.378139, 31.137117],
      [121.376294, 31.140432],
      [121.381165, 31.14101],
      [121.381326, 31.137585],
      [121.381497, 31.135069],
      [121.381712, 31.133995],
      [121.383461, 31.130845],
      [121.385767, 31.126473],
      [121.383841, 31.126229],
      [121.376825, 31.124521],
      [121.370184, 31.122849],
      [121.37337, 31.117963],
      [121.37616, 31.114004],
      [121.374808, 31.113297],
      [121.374507, 31.112911],
      [121.371857, 31.111607],
      [121.368521, 31.110403],
      [121.365635, 31.109779],
      [121.362287, 31.109025],
      [121.363628, 31.105672],
      [121.363628, 31.105672],
      [121.362926, 31.100887],
      [121.361048, 31.105112],
      [121.359347, 31.10835],
      [121.356917, 31.112691],
      [121.35357, 31.111341],
      [121.352411, 31.112719],
      [121.352014, 31.112544],
      [121.351864, 31.112801],
      [121.351371, 31.112618],
      [121.350995, 31.113077],
      [121.350609, 31.112948],
      [121.350416, 31.113518],
      [121.354289, 31.115428],
      [121.352175, 31.118606],
      [121.348753, 31.118138]
    ]
  },
  {
    color: '#ff8c00',
    path: [
      [121.385767, 31.126473],
      [121.383841, 31.126229],
      [121.376825, 31.124521],
      [121.370184, 31.122849],
      [121.37337, 31.117963],
      [121.37616, 31.114004],
      [121.378971, 31.109618],
      [121.379893, 31.1079],
      [121.381095, 31.105576],
      [121.384635, 31.098888],
      [121.386926, 31.094479],
      [121.394082, 31.096702],
      [121.400004, 31.098466],
      [121.404553, 31.099844],
      [121.407922, 31.100836],
      [121.406742, 31.10385],
      [121.405186, 31.10734],
      [121.406785, 31.108002],
      [121.405262, 31.111529],
      [121.403202, 31.110555],
      [121.399962, 31.109104],
      [121.397644, 31.11287],
      [121.397108, 31.113568],
      [121.3964, 31.113476],
      [121.395541, 31.114413],
      [121.393782, 31.117775],
      [121.39376, 31.118547],
      [121.393127, 31.119337],
      [121.395799, 31.123231],
      [121.399382, 31.126657],
      [121.397601, 31.127548],
      [121.396078, 31.12819],
      [121.394812, 31.128448],
      [121.393728, 31.12842],
      [121.389941, 31.127492]
    ]
  },
  {
    color: '#7cfc00',
    path: [
      [121.364621, 31.096174],
      [121.362926, 31.100887],
      [121.358967, 31.099757],
      [121.358344, 31.100823],
      [121.353538, 31.099775],
      [121.351263, 31.099362],
      [121.350437, 31.101649],
      [121.349246, 31.104856],
      [121.34842, 31.106886],
      [121.349611, 31.107244],
      [121.349536, 31.107694],
      [121.350319, 31.107648],
      [121.350437, 31.106711],
      [121.351843, 31.106812],
      [121.351725, 31.107795],
      [121.358537, 31.109439],
      [121.362926, 31.100887],
      [121.363628, 31.105672],
      [121.362287, 31.109025],
      [121.365635, 31.109779],
      [121.368521, 31.110403],
      [121.371857, 31.111607],
      [121.374507, 31.112911],
      [121.374808, 31.113297],
      [121.37616, 31.114004],
      [121.378971, 31.109618],
      [121.379893, 31.1079],
      [121.381095, 31.105576],
      [121.384635, 31.098888],
      [121.386926, 31.094479],
      [121.381798, 31.093009],
      [121.374051, 31.09074],
      [121.372517, 31.093808],
      [121.366445, 31.091838]
    ]
  }
]

export const MAP_MENU = {
  BlockView: [
    { label: '摄像头', num: 34, total: 42, class: 'video' },
    { label: '单兵位置', num: '', total: 5, class: 'danbin' },
    { label: '鹰眼摄像头', num: 34, total: 42, class: 'yingyan' },
    { label: '门禁摄像头', num: 34, total: 42, class: 'menjin' },
    { label: '服务器', num: 34, total: 42, class: 'service' },
    { label: '非警务110车辆', num: '', total: 34, class: 'car' },
    { label: '其他设备', num: 34, total: 42, class: 'other' },
    { label: '清空图层', num: '', total: '', class: 'close' }
  ],
  FireView: [
    { label: '摄像头', num: 34, total: 42, class: 'video' },
    { label: '烟感器', num: 34, total: 42, class: 'service' },
    { label: '单兵位置', num: '', total: 5, class: 'danbin' },
    { label: '水系统', num: 34, total: 42, class: 'water2' },
    { label: '可燃探测器', num: 34, total: 42, class: 'keran' },
    { label: '室外消防栓', num: 34, total: 42, class: 'fire' },
    { label: '清空图层', num: '', total: '', class: 'close' }
  ],
  FloodView: [
    // { label: '地面积水', num: 34, total: 42, class: 'video' },
    { label: '人员转移点', num: '', total: 5, class: 'zhuanyi' },
    { label: '应急抢险物资仓库', num: '', total: 4, class: 'cangku' },
    { label: '易积水预警', num: '', total: 1, class: 'water' },
    // { label: '积水感知', num: 34, total: 42, class: 'water' },
    { label: '单兵位置', num: '', total: 5, class: 'danbin' },
    { label: '视频监控', num: '', total: 5, class: 'jiance' },
    { label: '村居网格', num: '', total: 100, class: 'community' },
    { label: '抢险力量责任区划', num: '', total: 7, class: 'power' },
    { label: '清空图层', num: '', total: '', class: 'close' }
  ],
  SignView: [
    { label: '摄像头', num: 34, total: 42, class: 'video' },
    { label: '单兵位置', num: '', total: 5, class: 'danbin' },
    { label: '鹰眼摄像头', num: 34, total: 42, class: 'yingyan' },
    { label: '服务器', num: 34, total: 42, class: 'service' },
    { label: '其他设备', num: 34, total: 42, class: 'other' },
    { label: '清空图层', num: '', total: '', class: 'close' }
  ]
}

export const HeatMapData = [
  {
    count: 12,
    x: '4385.441368963452',
    y: '-20638.786524233987'
  },
  {
    count: 12,
    x: '3914.3237224226',
    y: '-19053.3493558921'
  },
  {
    count: 13,
    x: '7402.4589748742',
    y: '-17574.8421829216'
  },
  {
    count: 14,
    x: '4362.847462948472',
    y: '-20661.919384134595'
  },
  {
    count: 15,
    x: '5949.675445742524',
    y: '-19202.423898484238'
  },
  {
    count: 16,
    x: '6494.74012802376',
    y: '-18365.16165870723'
  },
  {
    count: 17,
    x: '6168.719992371287',
    y: '-20758.477990850202'
  },
  {
    count: 18,
    x: '6185.226699370483',
    y: '-19267.41636532593'
  },
  {
    count: 19,
    x: '4222.176515735707',
    y: '-22486.081420045564'
  },
  {
    count: 10,
    x: '2530.7436245961903',
    y: '-22620.46898420664'
  },
  {
    count: 11,
    x: '4585.441368963452',
    y: '-20638.786524233987'
  },
  {
    count: 12,
    x: '4914.3237224226',
    y: '-19053.3493558921'
  },
  {
    count: 13,
    x: '7502.4589748742',
    y: '-17574.8421829216'
  },
  {
    count: 14,
    x: '4362.847462948472',
    y: '-22620.919384134595'
  },
  {
    count: 15,
    x: '5949.675445742524',
    y: '-22620.423898484238'
  },
  {
    count: 16,
    x: '6494.74012802376',
    y: '-22620.16165870723'
  },
  {
    count: 17,
    x: '6168.719992371287',
    y: '-18365.477990850202'
  },
  {
    count: 18,
    x: '6185.226699370483',
    y: '-18365.41636532593'
  },
  {
    count: 19,
    x: '4222.176515735707',
    y: '-18365.081420045564'
  },
  {
    count: 20,
    x: '2530.7436245961903',
    y: '-22620.46898420664'
  },
  {
    count: 19,
    x: '6185.719992371287',
    y: '-20758.477990850202'
  },
  {
    count: 19,
    x: '6168.226699370483',
    y: '-19267.41636532593'
  },
  {
    count: 19,
    x: '4232.176515735707',
    y: '-19267.081420045564'
  },
  {
    count: 19,
    x: '2539.7436245961903',
    y: '-19267.46898420664'
  },
  {
    count: 19,
    x: '4232.675445742524',
    y: '-22620.423898484238'
  },
  {
    count: 19,
    x: '4232.74012802376',
    y: '-22620.16165870723'
  },
  {
    count: 19,
    x: '4232.719992371287',
    y: '-18365.477990850202'
  },
  {
    count: 19,
    x: '4232.226699370483',
    y: '-18365.41636532593'
  },
  {
    count: 19,
    x: '5232.226699370483',
    y: '-18365.41636532593'
  },
  {
    count: 19,
    x: '5232.226699370483',
    y: '-19365.41636532593'
  },
  {
    count: 19,
    x: '6232.226699370483',
    y: '-19365.41636532593'
  },
  {
    count: 19,
    x: '7232.226699370483',
    y: '-19365.41636532593'
  },
  {
    count: 19,
    x: '7232.226699370483',
    y: '-24365.41636532593'
  },
  {
    count: 19,
    x: '6232.226699370483',
    y: '-24365.41636532593'
  },
  {
    count: 19,
    x: '6232.226699370483',
    y: '-26365.41636532593'
  },
  {
    count: 19,
    x: '6232.226699370483',
    y: '-16365.41636532593'
  },
  {
    count: 19,
    x: '5232.226699370483',
    y: '-16365.41636532593'
  },
  {
    count: 19,
    x: '5232.226699370483',
    y: '-15365.41636532593'
  },
  {
    count: 19,
    x: '6232.226699370483',
    y: '-13365.41636532593'
  },
  {
    count: 19,
    x: '5532.226699370483',
    y: '-16365.41636532593'
  },
  {
    count: 19,
    x: '5732.226699370483',
    y: '-16565.41636532593'
  },
  {
    count: 19,
    x: '5832.226699370483',
    y: '-16965.41636532593'
  }
]

export const ZHUANYI_DATA = [
  {
    x: '3682.337763674726',
    y: '-19357.10375057171',
    name: '沈杜公路5919弄P7停车场',
    peopleNum: 600,
    type: '停车场',
    contact: '',
    telephone: '',
    videoList: [
      { label: '北通道东', hls: 'qP5GKuIrB1F349RP8CUGOT' },
      { label: '北通道西', hls: 'qP5GKuIrB1F349RP8CUH49' },
      { label: '南通道向西', hls: 'qP5GKuIrB1F349RP8CUH95' },
      { label: '主通道向东', hls: 'qP5GKuIrB1F349RP8DT21I' },
      { label: '东通道向南', hls: 'qP5GKuIrB1F349RP8DT29S' },
      { label: '正门进口', hls: 'qP5GKuIrB1F349RP8DT2FG' },
      { label: '主通道向西', hls: 'qP5GKuIrB1F349RP8DT2K9' },
      { label: '南通道向东', hls: 'qP5GKuIrB1F349RP8ERJFM' },
      { label: '中通道向北', hls: 'qP5GKuIrB1F349RP8ERJNH' },
      { label: '西正门向南', hls: 'qP5GKuIrB1F349RP8ERJU4' },
      { label: '中通道向南', hls: 'qP5GKuIrB1F349RP8ERK09' },
      { label: '东通道向北', hls: 'qP5GKuIrB1F349RP8ERK6Q' }
    ]
  },
  {
    x: '3907.575210833068',
    y: '-19881.024372605334',
    name: '沈杜公路P8停车场浦星公路西200米',
    peopleNum: 1600,
    type: '停车场',
    contact: '',
    telephone: '',
    videoList: [
      { label: 'C01 主出入口1-2号门', hls: 'qP5GKuIrB1F349RP8CUGTU' },
      { label: 'C02 主出入口2-2号门', hls: 'qP5GKuIrB1F349RP8CUH0K' },
      { label: 'C04 次出入口2-4号门', hls: 'qP5GKuIrB1F349RP8DT1U1' },
      { label: 'C05 次出入口3-1号主路', hls: 'qP5GKuIrB1F349RP8DT24L' },
      { label: 'C06  单元1北通道', hls: 'qP5GKuIrB1F349RP8DT2BB' },
      { label: 'C07 单元2北通道', hls: 'qP5GKuIrB1F349RP8DT2HE' },
      { label: 'C10 单元10北通道', hls: 'qP5GKuIrB1F349RP8ERJBG' },
      { label: 'C12 垃圾房通道2', hls: 'qP5GKuIrB1F349RP8ERJDT' },
      { label: 'C16 单元10东通道', hls: 'qP5GKuIrB1F349RP8ERJK2' },
      { label: 'C32 三区两通道前面', hls: 'qP5GKuIrB1F349RP8ERJP8' },
      { label: 'C49 单元4-3', hls: 'qP5GKuIrB1F349RP8ERK39' },
      { label: 'C79 单元12-1', hls: 'qP5GKuIrB1F349RP8ERKC8' },
      { label: 'C33 工勤区后面', hls: 'qP5GKuIrB1F349RP8ERKFF' },
      { label: 'C34 三区两通道后面', hls: 'qP5GKuIrB1F349RP8ERKK3' },
      { label: 'C35 单元1-1A-1', hls: 'qP5GKuIrB1F349RP8FQ57C' },
      { label: 'C36 单元1-1F-2', hls: 'qP5GKuIrB1F349RP8FQ5C2' },
      { label: 'C37 单元1-1F-3', hls: 'qP5GKuIrB1F349RP8FQ5E5' },
      { label: 'C38 单元1-1F-4', hls: 'qP5GKuIrB1F349RP8FQ5G5' },
      { label: 'C44 单元2-2', hls: 'qP5GKuIrB1F349RP8FQ5KI' },
      { label: 'C45 单元3-1', hls: 'qP5GKuIrB1F349RP8FQ5MQ' },
      { label: 'C46 单元3-2', hls: 'qP5GKuIrB1F349RP8FQ5RS' },
      { label: 'C88 1号一脱', hls: 'qP5GKuIrB1F349RP8FQ5S9' },
      { label: 'C89 1号二脱', hls: 'qP5GKuIrB1F349RP8FQ61Q' },
      { label: 'C90 2号一脱', hls: 'qP5GKuIrB1F349RP8FQ64V' },
      { label: 'C91 2号二脱', hls: 'qP5GKuIrB1F349RP8FQ67G' },
      { label: 'C100 物资通道', hls: 'qP5GKuIrB1F349RP8FQ694' },
      { label: 'C85 工勤区1楼走廊', hls: 'qP5GKuIrB1F349RP8FQ6CI' },
      { label: 'C84 中区咖啡室2', hls: 'qP5GKuIrB1F349RP8GON15' },
      { label: 'C81 中区接待室', hls: 'qP5GKuIrB1F349RP8GON6E' },
      { label: 'C86 工勤区2楼走廊', hls: 'qP5GKuIrB1F349RP8GON9C' }
    ]
  },
  {
    x: '4298.268954571851',
    y: '-23485.471571598366',
    name: '汇延路30号',
    peopleNum: 800,
    type: '空置校园',
    contact: '赵全友',
    telephone: '17521059926',
    videoList: []
  },
  {
    x: '3559.0319834852426',
    y: '-22599.62654149478',
    name: '鲁康路475号',
    peopleNum: 400,
    type: '空置校园',
    contact: '赵全友',
    telephone: '17521059926',
    videoList: []
  },
  {
    x: '2619.9738728837638',
    y: '-22415.700125190964',
    name: '永高路225号',
    peopleNum: 400,
    type: '空置校园',
    contact: '赵全友',
    telephone: '17521059926',
    videoList: []
  }
]

export const CANGKU_DATA = [
  // {
  //   x: '5662.8815050403255',
  //   y: '-13677.899132569824',
  //   name: '苏民村微型消防站防汛物资仓库',
  //   videoList: [
  //     { label: '苏民村微型消防站防汛物资仓库门口', hls: '8775541596883136' },
  //     { label: '苏民村微型消防站防汛物资仓库内', hls: '8775541596883137' }
  //   ]
  // },
  {
    x: '7657.6664302415',
    y: '-17599.8852995223',
    name: '沈杜路1589号革新村委防汛仓库',
    videoList: [
      { label: '革新村防汛仓库内', hls: '8778856053606592' },
      { label: '革新村防汛仓库门口', hls: '8778856053606593' }
    ]
  },
  {
    x: '6438.93898792506',
    y: '-25439.036942618382',
    name: '光继村微型消防站防汛物资仓库',
    videoList: [
      { label: '光继村微型消防站防汛物资仓库内', hls: '8775544534468800' },
      { label: '光继村微型消防站防汛物资仓库门口', hls: '8775544534468801' }
    ]
  },
  {
    x: '5966.712117774127',
    y: '-18180.28377712037',
    name: '沈杜公路2788号城运中心防汛仓库',
    videoList: [
      { label: '城运中心防汛仓库1', hls: '8678179863070912' },
      { label: '城运中心防汛仓库2', hls: '8678278432884928' },
      { label: '城运中心防汛仓库门口', hls: '8678278432950464' },
      { label: '城运中心防汛仓库4', hls: '8678278432950465' },
      { label: '城运中心防汛仓库5', hls: '8678278433016000' }
    ]
  },
  {
    x: '3889.854406753328',
    y: '-19055.807933113032',
    name: '瑞和雅苑四街区防汛仓库',
    videoList: [
      { label: '瑞和雅苑四街区防汛仓库内', hls: '8778918626855104' },
      { label: '瑞和雅苑四街区防汛仓库门口', hls: '8778918626920640' }
    ]
  }
]

export const WATER_DATA = [
  {
    count: 10,
    x: '4275.964620332988',
    y: '-17406.09226137909'
  }
]

export const JIANCE_LIST = [
  {
    videoSrc: '',
    name: 'C102东风村4组19号',
    hls: 'qP5GKuIrB1F349RP9G8LBS',
    x: '7117.51354016924',
    y: '-14688.705209768419'
  },
  {
    videoSrc: '',
    name: 'C146东风村10组7号',
    hls: 'qP5GKuIrB1F349RP9DD0LT',
    x: '5908.361264244666',
    y: '-14090.1405601816'
  },
  {
    videoSrc: '',
    name: 'C160联胜1组西联胜路口',
    hls: 'qP5GKuIrB1F349RPA2D7PO',
    x: '6802.036295275785',
    y: '-17065.58281834757'
  },
  {
    videoSrc: '',
    name: 'C43联民村8组55号西通道',
    hls: 'qP5GKuIrB1F349RPAT4PEL',
    x: '1844.0804287555097',
    y: '-21398.85679284368'
  },
  {
    videoSrc: '',
    name: '汇南村2组15号南通道',
    hls: 'qP5GKuIrB1F349RPJMGSAS',
    x: '8763.823287394247',
    y: '-24796.006431001162'
  }
]

export const FLOOD_POWER_DATA = [
  {
    name: '上海闵行市政建设有限公司',
    value: 14,
    children: null,
    contact: '金国祥',
    depatname: '总经理',
    telephone: '18049734119',
    num: 25,
    area: '昌林路以北',
    x: '5713.964128402955',
    y: '-15300.18310969178',
    top: '12%',
    left: '20%',
    color: 'rgba(227,75,96,0.5)',
    textColor: 'rgb(227,75,96)',
    rings: [
      [3829.946208412288, -18746.553471029918],
      [2440.6029796606485, -12489.369150259365],
      [2654.2375560528526, -12421.417253910642],
      [2814.552485390801, -12880.786948906105],
      [3770.0786150115605, -12404.440319498086],
      [4110.317850985356, -13111.002919727502],
      [4802.375232256817, -12975.089555560033],
      [4933.910637877523, -13200.540973694386],
      [5307.661667358608, -13076.272505814582],
      [5819.998848061549, -12919.728536288509],
      [5936.188106288939, -13255.181182121323],
      [6774.682446375933, -13035.55474109015],
      [6914.675742036838, -13616.179800442533],
      [8302.558348835339, -13332.592654934642],
      [9173.948892756433, -15545.491321206702],
      [7136.812026159936, -16016.577581871263],
      [7534.87275982081, -17100.576309979748],
      [9876.31052171899, -16673.78993281516],
      [4871.114848654928, -18676.694730132855]
    ]
  },
  {
    name: '上海成铭建筑工程有限公司',
    value: 14,
    children: null,
    contact: '袁成龙',
    depatname: '总经理',
    telephone: '13918954584',
    num: 9,
    area: '北至闵瑞路，西至浦星公路，南至大治河',
    x: '5660.9329028973825',
    y: '-21352.19901083674',
    top: '28%',
    left: '210px',
    color: 'rgba(76,79,212,0.5)',
    textColor: 'rgb(76,79,212)',
    rings: [
      [3948.987843964203, -20970.307799138336],
      [6311.783315247717, -20238.414307030052],
      [7120.6924019195485, -19812.54678304566],
      [7127.706806006047, -20595.6997436236],
      [7911.173439532829, -20270.062418792935],
      [8558.652705828823, -23283.350274929697],
      [9831.137082691976, -23418.867394732053],
      [9856.56923553519, -24164.525123062474],
      [4002.017688825977, -23911.4172520517]
    ]
  },
  {
    name: '上海霖炬建设工程有限公司',
    value: 14,
    children: null,
    contact: '肖林凯',
    depatname: '总经理',
    telephone: '13764230719',
    num: 7,
    area: '北至昌林路，西至浦星公路，南至闵瑞路',
    x: '5918.265709047612',
    y: '-19337.053875764555',
    top: '45%',
    left: '16%',
    color: 'rgba(221,236,89,0.5)',
    textColor: 'rgb(221,236,89)',
    rings: [
      [4871.114848654928, -18676.694730132855],
      [9876.31052171899, -16673.78993281516],
      [9990.481798822757, -17034.005678398156],
      [8725.755867949898, -17838.35620570558],
      [7109.459203881028, -19142.427131524866],
      [7120.6924019195485, -19812.54678304566],
      [6311.783315247717, -20238.414307030052],
      [3948.987843964203, -20970.307799138336],
      [3855.6461710337358, -19230.568659810386]
    ]
  },
  {
    name: '上海达富环境科技有限公司',
    value: 14,
    children: null,
    contact: '周泽',
    depatname: '总经理',
    telephone: '13917632193',
    num: 11,
    area: '南至大治河，东至浦星公路',
    x: '2729.688229085767',
    y: '-21894.616277294335',
    top: '62%',
    left: '260px',
    color: 'rgba(183,65,235,0.5)',
    textColor: 'rgb(183,65,235)',
    rings: [
      [1990.4065457390939, -23834.8922735709],
      [1157.4236111906025, -20031.731254011458],
      [1521.0097235895855, -22392.147246733195],
      [3871.5473792629937, -19234.053999096905],
      [3948.987843964203, -20970.307799138336],
      [4002.017688825977, -23911.4172520517]
    ]
  },
  {
    name: '上海浦岗建设工程有限公司',
    value: 14,
    children: null,
    contact: '夏海频',
    depatname: '总经理',
    telephone: '18049894398',
    num: 23,
    area: '大治河以南',
    x: '6310.158345274348',
    y: '-24949.615607252188',
    top: '76%',
    left: '50px',
    color: 'rgba(70,212,110,0.5)',
    textColor: 'rgb(70,212,110)',
    rings: [
      [1990.4065457390939, -23834.8922735709],
      [4002.017688825977, -23911.4172520517],
      [9856.56923553519, -24164.525123062474],
      [9406.913916605481, -26402.774559581394],
      [6539.411715912995, -26383.516605958237],
      [4193.531835762359, -26380.17264170901],
      [2136.898913807138, -26316.57606337797]
    ]
  }
]

export const COMMUNITY_AREA = [
  {
    type: '村委网格',
    name: '北徐村村委会',
    code: '121501'
  },
  {
    type: '村委网格',
    name: '东风村村委会',
    code: '121502'
  },
  {
    type: '村委网格',
    name: '杜行村村委会',
    code: '121503'
  },
  {
    type: '村委网格',
    name: '革新村村委会',
    code: '121504'
  },
  {
    type: '村委网格',
    name: '光继村村委会',
    code: '121505'
  },
  {
    type: '村委网格',
    name: '恒星村村委会',
    code: '121506'
  },
  {
    type: '村委网格',
    name: '汇北村村委会',
    code: '121507'
  },
  {
    type: '村委网格',
    name: '汇东村村委会',
    code: '121508'
  },
  {
    type: '村委网格',
    name: '汇红村村委会',
    code: '121509'
  },
  {
    type: '村委网格',
    name: '汇南村村委会',
    code: '121510'
  },
  {
    type: '村委网格',
    name: '汇西村村委会',
    code: '121511'
  },
  {
    type: '村委网格',
    name: '汇中村村委会',
    code: '121512'
  },
  {
    type: '村委网格',
    name: '建东村村委会',
    code: '121513'
  },
  {
    type: '村委网格',
    name: '建岗村村委会',
    code: '121514'
  },
  {
    type: '村委网格',
    name: '建新村村委会',
    code: '121515'
  },
  {
    type: '村委网格',
    name: '建中村村委会',
    code: '121516'
  },
  {
    type: '村委网格',
    name: '康桥村村委会',
    code: '121517'
  },
  {
    type: '村委网格',
    name: '立民村村委会',
    code: '121519'
  },
  {
    type: '村委网格',
    name: '联合村村委会',
    code: '121520'
  },
  {
    type: '村委网格',
    name: '联民村村委会',
    code: '121521'
  },
  {
    type: '村委网格',
    name: '联胜村村委会',
    code: '121522'
  },
  {
    type: '村委网格',
    name: '联星村村委会',
    code: '121523'
  },
  {
    type: '村委网格',
    name: '勤劳村村委会',
    code: '121524'
  },
  {
    type: '村委网格',
    name: '群益村村委会',
    code: '121525'
  },
  {
    type: '村委网格',
    name: '胜利村村委会',
    code: '121526'
  },
  {
    type: '村委网格',
    name: '苏民村村委会',
    code: '121527'
  },
  {
    type: '村委网格',
    name: '亭子村村委会',
    code: '121528'
  },
  {
    type: '村委网格',
    name: '万里村村委会',
    code: '121529'
  },
  {
    type: '村委网格',
    name: '为民村村委会',
    code: '121530'
  },
  {
    type: '村委网格',
    name: '先进村村委会',
    code: '121531'
  },
  {
    type: '村委网格',
    name: '新风村村委会',
    code: '121532'
  },
  {
    type: '村委网格',
    name: '徐凌村村委会',
    code: '121533'
  },
  {
    type: '村委网格',
    name: '叶凌村村委会',
    code: '121534'
  },
  {
    type: '村委网格',
    name: '永丰村村委会',
    code: '121535'
  },
  {
    type: '村委网格',
    name: '永建村村委会',
    code: '121536'
  },
  {
    type: '村委网格',
    name: '永新村村委会',
    code: '121537'
  },
  {
    type: '村委网格',
    name: '友建村村委会',
    code: '121538'
  },
  {
    type: '村委网格',
    name: '跃进村村委会',
    code: '121539'
  },
  {
    type: '村委网格',
    name: '张行村村委会',
    code: '121540'
  },
  {
    type: '村委网格',
    name: '镇北村村委会',
    code: '121541'
  },
  {
    type: '村委网格',
    name: '正义村村委会',
    code: '121542'
  },
  {
    type: '村委网格',
    name: '知新村村委会',
    code: '121543'
  },
  {
    type: '居委网格',
    name: '宝邸第一居委会',
    code: '121547'
  },
  {
    type: '居委网格',
    name: '杜行居委会',
    code: '121548'
  },
  {
    type: '居委网格',
    name: '红梅苑居委会',
    code: '121549'
  },
  {
    type: '居委网格',
    name: '汇秀景苑居委会',
    code: '121550'
  },
  {
    type: '居委网格',
    name: '聚缘居委会',
    code: '121551'
  },
  {
    type: '居委网格',
    name: '鲁汇居委会',
    code: '121552'
  },
  {
    type: '居委网格',
    name: '闵浦第三居委会',
    code: '121553'
  },
  {
    type: '居委网格',
    name: '闵浦第一居委会',
    code: '121554'
  },
  {
    type: '居委网格',
    name: '闵浦新苑第二居委会',
    code: '121555'
  },
  {
    type: '居委网格',
    name: '浦航新城第八居委会',
    code: '121556'
  },
  {
    type: '居委网格',
    name: '浦航新城第二居委会',
    code: '121557'
  },
  {
    type: '居委网格',
    name: '浦航新城第六居委会',
    code: '121558'
  },
  {
    type: '居委网格',
    name: '浦航新城第七居委会',
    code: '121559'
  },
  {
    type: '居委网格',
    name: '浦航新城第三居委会',
    code: '121560'
  },
  {
    type: '居委网格',
    name: '浦航新城第四居委会',
    code: '121561'
  },
  {
    type: '居委网格',
    name: '浦航新城第五居委会',
    code: '121562'
  },
  {
    type: '居委网格',
    name: '浦江宝邸第二居委会',
    code: '121563'
  },
  {
    type: '居委网格',
    name: '浦江馨都居委会',
    code: '121564'
  },
  {
    type: '居委网格',
    name: '浦润苑居委会',
    code: '121565'
  },
  {
    type: '居委网格',
    name: '瑞和城第二居委会',
    code: '121566'
  },
  {
    type: '居委网格',
    name: '瑞和城第六居委会',
    code: '121567'
  },
  {
    type: '居委网格',
    name: '瑞和城第三居委会',
    code: '121568'
  },
  {
    type: '居委网格',
    name: '瑞和城第四居委会',
    code: '121569'
  },
  {
    type: '居委网格',
    name: '瑞和城第五居委会',
    code: '121570'
  },
  {
    type: '居委网格',
    name: '瑞和城第一居委会',
    code: '121571'
  },
  {
    type: '居委网格',
    name: '瑞和华苑居委会',
    code: '121572'
  },
  {
    type: '居委网格',
    name: '瑞和雅苑第二居委会',
    code: '1215a1'
  },
  {
    type: '居委网格',
    name: '瑞和雅苑第一居委会',
    code: '121573'
  },
  {
    type: '居委网格',
    name: '欣佳宝邸居委会',
    code: '121574'
  },
  {
    type: '居委网格',
    name: '新汇绿苑居委会',
    code: '121575'
  },
  {
    type: '居委网格',
    name: '馨和雅苑居委会',
    code: '1215a2'
  },
  {
    type: '居委网格',
    name: '永康城第八居委会',
    code: '121576'
  },
  {
    type: '居委网格',
    name: '永康城第二居委会',
    code: '121577'
  },
  {
    type: '居委网格',
    name: '永康城第九居委会',
    code: '121578'
  },
  {
    type: '居委网格',
    name: '永康城第六居委会',
    code: '121579'
  },
  {
    type: '居委网格',
    name: '永康城第七居委会',
    code: '121580'
  },
  {
    type: '居委网格',
    name: '永康城第三居委会',
    code: '121581'
  },
  {
    type: '居委网格',
    name: '永康城第四居委会',
    code: '121582'
  },
  {
    type: '居委网格',
    name: '永康城第五居委会',
    code: '121583'
  },
  {
    type: '居委网格',
    name: '永康城第一居委会',
    code: '121584'
  },
  {
    type: '居委网格',
    name: '智汇园居委会',
    code: '121585'
  },
  {
    type: '居委网格',
    name: '中虹浦江苑居委会',
    code: '121586'
  }
]

export const COMMUNITY_AREA_COLOR = [
  { color: 'rgba(67, 128, 246, 0.5)', lineColor: [67, 128, 246, 1] },
  { color: 'rgba(238, 78, 95, 0.5)', lineColor: [238, 78, 95, 1] },
  { color: 'rgba(104, 10, 46, 0.5)', lineColor: [104, 10, 46, 1] },
  { color: 'rgba(191, 217, 143, 0.5)', lineColor: [191, 217, 143, 1] },
  { color: 'rgba(100, 199, 249, 0.5)', lineColor: [100, 199, 249, 1] },
  { color: 'rgba(140, 192, 214, 0.5)', lineColor: [140, 192, 214, 1] },
  { color: 'rgba(72, 230, 76, 0.5)', lineColor: [72, 230, 76, 1] },
  { color: 'rgba(23, 66, 137, 0.5)', lineColor: [23, 66, 137, 1] },
  { color: 'rgba(243, 201, 13, 0.5)', lineColor: [243, 201, 13, 1] },
  { color: 'rgba(0, 84, 46, 0.5)', lineColor: [0, 84, 46, 1] },
  { color: 'rgba(245, 134, 4, 0.5)', lineColor: [245, 134, 4, 1] },
  { color: 'rgba(125, 182, 69, 0.5)', lineColor: [125, 182, 69, 1] },
  { color: 'rgba(158, 185, 239, 0.5)', lineColor: [158, 185, 239, 1] },
  { color: 'rgba(35, 108, 69, 0.5)', lineColor: [35, 108, 69, 1] },
  { color: 'rgba(81, 49, 139, 0.5)', lineColor: [81, 49, 139, 1] },
  { color: 'rgba(201, 204, 136, 0.5)', lineColor: [201, 204, 136, 1] },
  { color: 'rgba(53, 63, 77, 0.5)', lineColor: [53, 63, 77, 1] },
  { color: 'rgba(37, 219, 233, 0.5)', lineColor: [37, 219, 233, 1] },
  { color: 'rgba(171, 203, 108, 0.5)', lineColor: [171, 203, 108, 1] },
  { color: 'rgba(247, 235, 88, 0.5)', lineColor: [247, 235, 88, 1] },
  { color: 'rgba(191, 63, 215, 0.5)', lineColor: [191, 63, 215, 1] },
  { color: 'rgba(121, 55, 47, 0.5)', lineColor: [121, 55, 47, 1] },
  { color: 'rgba(122, 67, 165, 0.5)', lineColor: [122, 67, 165, 1] },
  { color: 'rgba(201, 34, 34, 0.5)', lineColor: [201, 34, 34, 1] },
  { color: 'rgba(170, 38, 113, 0.5)', lineColor: [170, 38, 113, 1] },
  { color: 'rgba(170, 95, 212, 0.5)', lineColor: [170, 95, 212, 1] },
  { color: 'rgba(140, 96, 165, 0.5)', lineColor: [140, 96, 165, 1] },
  { color: 'rgba(23, 95, 111, 0.5)', lineColor: [23, 95, 111, 1] },
  { color: 'rgba(121, 163, 230, 0.5)', lineColor: [121, 163, 230, 1] },
  { color: 'rgba(147, 5, 11, 0.5)', lineColor: [147, 5, 11, 1] },
  { color: 'rgba(83, 56, 110, 0.5)', lineColor: [83, 56, 110, 1] },
  { color: 'rgba(147, 31, 208, 0.5)', lineColor: [147, 31, 208, 1] },
  { color: 'rgba(13, 197, 151, 0.5)', lineColor: [13, 197, 151, 1] },
  { color: 'rgba(107, 139, 201, 0.5)', lineColor: [107, 139, 201, 1] },
  { color: 'rgba(103, 61, 79, 0.5)', lineColor: [103, 61, 79, 1] },
  { color: 'rgba(12, 109, 93, 0.5)', lineColor: [12, 109, 93, 1] },
  { color: 'rgba(179, 11, 70, 0.5)', lineColor: [179, 11, 70, 1] },
  { color: 'rgba(240, 87, 67, 0.5)', lineColor: [240, 87, 67, 1] },
  { color: 'rgba(93, 64, 32, 0.5)', lineColor: [93, 64, 32, 1] },
  { color: 'rgba(230, 38, 235, 0.5)', lineColor: [230, 38, 235, 1] },
  { color: 'rgba(102, 220, 8, 0.5)', lineColor: [102, 220, 8, 1] },
  { color: 'rgba(9, 188, 136, 0.5)', lineColor: [9, 188, 136, 1] },
  { color: 'rgba(170, 245, 10, 0.5)', lineColor: [170, 245, 10, 1] },
  { color: 'rgba(127, 162, 21, 0.5)', lineColor: [127, 162, 21, 1] },
  { color: 'rgba(163, 110, 177, 0.5)', lineColor: [163, 110, 177, 1] },
  { color: 'rgba(230, 150, 93, 0.5)', lineColor: [230, 150, 93, 1] },
  { color: 'rgba(195, 170, 216, 0.5)', lineColor: [195, 170, 216, 1] },
  { color: 'rgba(4, 209, 191, 0.5)', lineColor: [4, 209, 191, 1] },
  { color: 'rgba(187, 6, 1, 0.5)', lineColor: [187, 6, 1, 1] },
  { color: 'rgba(33, 216, 117, 0.5)', lineColor: [33, 216, 117, 1] },
  { color: 'rgba(93, 28, 6, 0.5)', lineColor: [93, 28, 6, 1] },
  { color: 'rgba(188, 41, 232, 0.5)', lineColor: [188, 41, 232, 1] },
  { color: 'rgba(240, 253, 248, 0.5)', lineColor: [240, 253, 248, 1] },
  { color: 'rgba(166, 99, 107, 0.5)', lineColor: [166, 99, 107, 1] },
  { color: 'rgba(217, 99, 172, 0.5)', lineColor: [217, 99, 172, 1] },
  { color: 'rgba(40, 172, 172, 0.5)', lineColor: [40, 172, 172, 1] },
  { color: 'rgba(242, 97, 56, 0.5)', lineColor: [242, 97, 56, 1] },
  { color: 'rgba(96, 40, 171, 0.5)', lineColor: [96, 40, 171, 1] },
  { color: 'rgba(206, 235, 156, 0.5)', lineColor: [206, 235, 156, 1] },
  { color: 'rgba(133, 112, 202, 0.5)', lineColor: [133, 112, 202, 1] },
  { color: 'rgba(211, 220, 125, 0.5)', lineColor: [211, 220, 125, 1] },
  { color: 'rgba(29, 6, 77, 0.5)', lineColor: [29, 6, 77, 1] },
  { color: 'rgba(44, 203, 76, 0.5)', lineColor: [44, 203, 76, 1] },
  { color: 'rgba(21, 106, 239, 0.5)', lineColor: [21, 106, 239, 1] },
  { color: 'rgba(149, 121, 58, 0.5)', lineColor: [149, 121, 58, 1] },
  { color: 'rgba(175, 16, 76, 0.5)', lineColor: [175, 16, 76, 1] },
  { color: 'rgba(172, 139, 15, 0.5)', lineColor: [172, 139, 15, 1] },
  { color: 'rgba(37, 13, 243, 0.5)', lineColor: [37, 13, 243, 1] },
  { color: 'rgba(163, 189, 74, 0.5)', lineColor: [163, 189, 74, 1] },
  { color: 'rgba(99, 53, 78, 0.5)', lineColor: [99, 53, 78, 1] },
  { color: 'rgba(36, 178, 101, 0.5)', lineColor: [36, 178, 101, 1] },
  { color: 'rgba(27, 27, 24, 0.5)', lineColor: [27, 27, 24, 1] },
  { color: 'rgba(195, 227, 165, 0.5)', lineColor: [195, 227, 165, 1] },
  { color: 'rgba(208, 199, 32, 0.5)', lineColor: [208, 199, 32, 1] },
  { color: 'rgba(239, 238, 90, 0.5)', lineColor: [239, 238, 90, 1] },
  { color: 'rgba(243, 113, 166, 0.5)', lineColor: [243, 113, 166, 1] },
  { color: 'rgba(64, 70, 131, 0.5)', lineColor: [64, 70, 131, 1] },
  { color: 'rgba(26, 164, 5, 0.5)', lineColor: [26, 164, 5, 1] },
  { color: 'rgba(84, 72, 74, 0.5)', lineColor: [84, 72, 74, 1] },
  { color: 'rgba(171, 212, 107, 0.5)', lineColor: [171, 212, 107, 1] },
  { color: 'rgba(185, 181, 10, 0.5)', lineColor: [185, 181, 10, 1] },
  { color: 'rgba(172, 130, 143, 0.5)', lineColor: [172, 130, 143, 1] },
  { color: 'rgba(244, 5, 135, 0.5)', lineColor: [244, 5, 135, 1] },
  { color: 'rgba(43, 215, 45, 0.5)', lineColor: [43, 215, 45, 1] },
  { color: 'rgba(116, 177, 182, 0.5)', lineColor: [116, 177, 182, 1] },
  { color: 'rgba(205, 115, 65, 0.5)', lineColor: [205, 115, 65, 1] },
  { color: 'rgba(245, 24, 37, 0.5)', lineColor: [245, 24, 37, 1] },
  { color: 'rgba(98, 121, 116, 0.5)', lineColor: [98, 121, 116, 1] },
  { color: 'rgba(147, 205, 11, 0.5)', lineColor: [147, 205, 11, 1] },
  { color: 'rgba(205, 197, 197, 0.5)', lineColor: [205, 197, 197, 1] },
  { color: 'rgba(73, 247, 66, 0.5)', lineColor: [73, 247, 66, 1] },
  { color: 'rgba(50, 160, 98, 0.5)', lineColor: [50, 160, 98, 1] },
  { color: 'rgba(140, 103, 189, 0.5)', lineColor: [140, 103, 189, 1] },
  { color: 'rgba(178, 156, 54, 0.5)', lineColor: [178, 156, 54, 1] },
  { color: 'rgba(232, 138, 129, 0.5)', lineColor: [232, 138, 129, 1] },
  { color: 'rgba(245, 167, 194, 0.5)', lineColor: [245, 167, 194, 1] },
  { color: 'rgba(210, 154, 66, 0.5)', lineColor: [210, 154, 66, 1] },
  { color: 'rgba(105, 6, 107, 0.5)', lineColor: [105, 6, 107, 1] },
  { color: 'rgba(192, 254, 57, 0.5)', lineColor: [192, 254, 57, 1] },
  { color: 'rgba(182, 221, 46, 0.5)', lineColor: [182, 221, 46, 1] }
]
